import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/images/logo-atulaya.png';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router";
import { LOGIN, Username, Password, RememberMe, URL_Start } from '../constant';
import { Form, Button } from 'react-bootstrap'
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import LoadingButton from '../pages/admin/commonComponent/LoadingButton';
import { useLocation } from 'react-router-dom';
let GlobalAccessKeyId = ''
let AccessKey = "";
const Signin = () => {
    const location = useLocation();
    const [checkbox, setCheckbox] = useState(localStorage.CheckBox == undefined ? false : true);
    let usernameref = useRef();
    let passwordref = useRef();
    let btnLogin = useRef();
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {

        if (location != null && location != '') {
            const params = new URLSearchParams(location.search);
            if (params != null && params != '') {
                window.GlobalBusiness = "0";
                AccessKey = params.get('Loginkey');
                GlobalAccessKeyId = params.get('Loginkey');
                AllowModuleChangeMove(AccessKey);
                console.log('QueryString:' + AccessKey);
            }
        }
        else {
            localStorage.setItem('Session', "");
            if (localStorage.getItem('Email') !== null) { formik.values.email = localStorage.getItem('Email'); }
            if (localStorage.getItem('Password') !== null) { formik.values.password = localStorage.getItem('Password'); }
            usernameref.current.focus();
            btnLogin.current.focus();
            var input1 = usernameref.current;
            var input2 = passwordref.current;
            input1.addEventListener("keyup", function (event) {
                if (event.key == undefined) {
                    return false;
                }
                if (event.key.toLowerCase() === "enter") {
                    event.preventDefault();
                    btnLogin.current.click();
                }
            });
            input2.addEventListener("keyup", function (event) {
                if (event.key == undefined) {
                    return false;
                }
                if (event.key.toLowerCase() === "enter") {
                    event.preventDefault();
                    btnLogin.current.click();
                }
            });
        }

    }, []);
    const formik = useFormik({
        initialValues: { email: '', password: '' },
        validationSchema: Yup.object({
            email: Yup.string().required('Email address is required.').email('Invalid email'),
            password: Yup.string().required('Password is required.')
        }),
        onSubmit: values => {
            callLogin(values);
        },
    });

    const handleCheckbox = (e) => {
        const checked = e.target.checked;
        if (checked)
            setCheckbox(true);
        else
            setCheckbox(false);
    };
    const callLogin = async (values) => {
        try {
            setIsLoading(true);
            const requestOptions = { UserName: values.email, Password: values.password, ModuleId: "3" };
            let url = URL_Start + 'users/verifyuser'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    localStorage.clear();
                    window.GlobalBusiness = "0";
                    let ds = data.Data;
                    if (ds != null) {
                        if (ds.objVerifiedUserInfo != null) {
                            if (checkbox == true) {
                                localStorage.setItem('Email', values.email);
                                localStorage.setItem('Password', values.password);
                                localStorage.setItem('CheckBox', checkbox);
                            }
                            const today = new Date();
                            const date = today.setDate(today.getDate());
                            const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                            localStorage.setItem("LoginDate",defaultValue);
                            localStorage.setItem('Session', "Active");
                            localStorage.setItem("UserAccessKey",ds.objVerifiedUserInfo.LoginActiveKey);
                            localStorage.setItem('LoggedInUser', ds.objVerifiedUserInfo.UserId);
                            localStorage.setItem('UserName', ds.objVerifiedUserInfo.UserName);
                            localStorage.setItem("UserGroups", JSON.stringify(ds.objUserAllowGroupsDetailsList));
                            localStorage.setItem("UserSubGroups", JSON.stringify(ds.objUserSubAllowGroupsList));
                            localStorage.setItem("UserAllowModule", JSON.stringify(ds.objUserAssignedModuleList));
                            if (ds.objUserAccessLocationDDL != null) {
                                const TypeObj = {
                                    'UserLocld': ds.objUserAccessLocationDDL.UserLocld,
                                    'label': ds.objUserAccessLocationDDL.CustUserLocation,
                                    'value': ds.objUserAccessLocationDDL.UserLocld,
                                    'ModuleId': ds.objUserAccessLocationDDL.ModuleId,
                                    'AccessGroupName': ds.objUserAccessLocationDDL.AccessGroupName,
                                    'IsDefaultGroup': ds.objUserAccessLocationDDL.IsDefaultGroup,
                                    'PageAccessGroupId': ds.objUserAccessLocationDDL.PageAccessGroupId,
                                    'disabled': false
                                }
                                localStorage.removeItem("SelectionOptonLoc");
                                localStorage.setItem("SelectionOptonLoc", JSON.stringify(TypeObj));
                            }
                            window.location.assign(`${process.env.PUBLIC_URL}/dashboard`);
                        }
                        else {
                            SweetAlert.fire({ title: "Invalid", text: "Wrong Credentials", icon: "error" })
                        }
                    }
                    else {
                        SweetAlert.fire({ title: "Invalid", text: "Wrong Credentials", icon: "error" })
                    }
                }
                else {
                    APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); setIsLoading(false);
                }
            }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } setIsLoading(false); })
        } catch (error) { APIError("-3"); setIsLoading(false); }
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Unauthorized user!", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    async function AllowModuleChangeMove(Accessskey) {

        try {
            localStorage.setItem('Session', "");
            localStorage.removeItem('LoggedInUser');
            localStorage.removeItem('UserName');
            localStorage.removeItem("UserGroups");
            localStorage.removeItem("UserSubGroups");
            localStorage.removeItem("UserAllowModule");
            if (Accessskey) {

                let url = URL_Start + 'Users/GetLogModuleChangeStatusDetails';
                let options = {
                    method: 'POST',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    data: { ModuleId: "3", SecretKey: `${Accessskey}` }
                };


                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    //localStorage.clear();
                    let data = response.data;
                    if (data.Success == true) {
                        let ds = data.Data;
                        if (ds != null) {

                            localStorage.setItem('Session', "Active");
                            localStorage.setItem("UserAccessKey",ds.objVerifiedUserInfo.LoginActiveKey);
                            localStorage.setItem('LoggedInUser', ds.objVerifiedUserInfo.UserId);
                            localStorage.setItem('UserName', ds.objVerifiedUserInfo.UserName);
                            localStorage.setItem("UserGroups", JSON.stringify(ds.objUserAllowGroupsDetailsList));
                            localStorage.setItem("UserSubGroups", JSON.stringify(ds.objUserSubAllowGroupsList));
                            localStorage.setItem("UserAllowModule", JSON.stringify(ds.objUserAssignedModuleList));
                            if (ds.objUserAccessLocationDDL != null) {
                                const TypeObj = {
                                    'UserLocld': ds.objUserAccessLocationDDL.UserLocld,
                                    'label': ds.objUserAccessLocationDDL.CustUserLocation,
                                    'value': ds.objUserAccessLocationDDL.UserLocld,
                                    'ModuleId': ds.objUserAccessLocationDDL.ModuleId,
                                    'AccessGroupName': ds.objUserAccessLocationDDL.AccessGroupName,
                                    'IsDefaultGroup': ds.objUserAccessLocationDDLIsDefaultGroup,
                                    'PageAccessGroupId': ds.objUserAccessLocationDDL.PageAccessGroupId,
                                    'disabled': false
                                }
                                localStorage.removeItem("SelectionOptonLoc");
                                localStorage.setItem("SelectionOptonLoc", JSON.stringify(TypeObj));
                            }


                            window.location.assign(`${process.env.PUBLIC_URL}/dashboard`);
                        }
                        else {
                            window.location.assign(`${process.env.PUBLIC_URL}/login`);
                        }
                    }
                    else {
                        window.location.assign(`${process.env.PUBLIC_URL}/login`);
                        //SweetAlert.fire({ title: "Wrong Login Key", text: "Unauthorized user!", icon: "info" });

                    }

                }
                else {
                    window.location.assign(`${process.env.PUBLIC_URL}/login`);
                    //SweetAlert.fire({ title: "Wrong Login Key", text: "Unauthorized user!", icon: "info" });
                }
            }


        } catch (error) {
            window.location.assign(`${process.env.PUBLIC_URL}/login`);
            console.log(error.message);
            // SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });

        }
    }


    return (
        <>
            {GlobalAccessKeyId == '' ? <Form onSubmit={formik.handleSubmit}>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="authentication-main">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            <div className="signinLogoHeader">
                                                <img src={logo} alt="" />
                                                <div className='logoTagLine'>Imaging <span>&amp;</span> Laboratories</div>
                                            </div>
                                            <div className="card mt-4">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                        <h4>{LOGIN}</h4>
                                                        <h6>{"Enter your Username and Password"}</h6>
                                                    </div>
                                                    <form className="theme-form" >
                                                        <div className="form-group">
                                                            <label className="col-form-label pt-0">{Username}</label>
                                                            <input className="form-control" type="text" name="email"
                                                                onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email}
                                                                placeholder="Email address" ref={usernameref}
                                                            />
                                                            {formik.touched.email && formik.errors.email ? (<div className='text-required'>{formik.errors.email}</div>) : null}
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="col-form-label">{Password}</label>
                                                            <input className="form-control" type="password" name="password"
                                                                onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password}
                                                                placeholder="Password" ref={passwordref}
                                                            />
                                                            {formik.touched.password && formik.errors.password ? (<div className='text-required'>{formik.errors.password}</div>) : null}
                                                        </div>
                                                        <div className="checkbox p-0">
                                                            <input id="checkbox1" type="checkbox" onClick={(e) => { handleCheckbox(e); }} checked={checkbox} />
                                                            <label htmlFor="checkbox1">{RememberMe}</label>
                                                        </div>
                                                        <div className="form-group form-row mt-3 mb-0">
                                                            {
                                                                isLoading ?
                                                                    <LoadingButton variantName='secondary' cssName="btn btn-primary btn-block" /> :
                                                                    <Button variant='secondary' type='submit' className="btn btn-primary btn-block" onClick={formik.handleSubmit} ref={btnLogin}>Login</Button>
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer />
                    </div>
                </div>
            </Form> : null}

        </>
    );
};

export default withRouter(Signin);