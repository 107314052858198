
import React, { useState, useEffect, useRef, Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useFormik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import ActiveToolTip from '../UI/ToolTips/ActiveToolTip';
import InactiveToolTip from '../UI/ToolTips/InactiveToolTip';
import { URL_Start } from '../../../constant';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Spinner } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';

let jsonArr = [];
let jsonrow = {
    "DoctorId": "0",
    "DoctorRefCatId": ""
}

let globalLocationfilter = "";
let globaleditFList = "";
let globallocationStateList = [];
let GlbLocationRegionList = [];
let glbSalesHierarchy = [];
const EditDoctorDetail = () => {
    let typeahead = useRef();
    const history = useHistory();
    let typeaheadCurrent = useRef(null);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [DoctorId, setDoctorId] = useState(localStorage.DoctorId == undefined ? "" : localStorage.DoctorId);
    const calendarRef = React.createRef();
    const [CallTypeData, setCallTypeData] = useState(0);
    const [SelectSpecializationD, SetSelectSpecializationD] = useState(null);
    const [regionList, setRegionList] = useState(0);
    const [cityList, setCityList] = useState(0);
    const [disablefields, setDisableFields] = useState({});
    const [ErrorPopUp, setErrorPopUp] = useState(0);
    const [tblRefDoctor, SettblRefDoctor] = useState([]);
    const [LocationFList, SetLocationFList] = useState([]);
    const [EditLocationFList, SetEditLocationFList] = useState([]);
    const [LocationTyOption, SetLocationTyOption] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [EditDoctorDetails, SetEditDoctorDetails] = useState(null);
    const [SpecializationDLists, SetSpecializationDLists] = useState([]);
    const [SpecializationTyOption, SetSpecializationTyOption] = useState([]);
    const [EditSpecializationDLists, SetEditSpecializationDLists] = useState([]);
    const [LocationZoneId, SetLocationZoneId] = useState(1);
    const [LocationStateList, SetLocationStateList] = useState([]);
    const [LocationStateSection, SetLocationStateSection] = useState([]);
    const [LocationRegionList, SetLocationRegionList] = useState([]);
    const [LGlbLocationRegionList, SetLGlbLocationRegionList] = useState([]);
    const [LocationDistrictList, SetLocationDistrictList] = useState([]);
    const [LGLocationDistrictList, SetLGLocationDistrictList] = useState([]);
    const [DrTitleDDL, SetDrTitleDDL] = useState([]);
    const [sapStatesList, setSAPStatesList] = useState([]);
    const [sapRegionsList, setSAPRegionsList] = useState([]);
    const [sapDistrictsList, setSAPDistrictsList] = useState([]);
    const [doctorbusinessmappingdata, setDoctorBusinessMappingData] = useState([]);
    const [EditSpecializationDListsD, SetEditSpecializationDListsD] = useState(null);
    const [exid, setEXID] = useState("0");
    const [isFinalButtonLoading, setIsFinalButtonLoading] = useState(false);
    const [LGLocationZoneList, SetLGLocationZoneList] = useState([{
        "zoneId": "1"
    }]);
    const [IsShowRegion, SetIsShowRegion] = useState(false);
    const isFirstRender = useRef(false);
    const isCheckedRender = useRef(false);
    const isStateCheckedRender = useRef(false);
    const [salesPersons, setSalesPersons] = useState([]);
    const [salesManagers, setSalesManagers] = useState([]);
    const [salesHeads, setSalesHeads] = useState([]);
    const [salesPersons2, setSalesPersons2] = useState([]);
    const [salesManagers2, setSalesManagers2] = useState([]);
    const [salesHeads2, setSalesHeads2] = useState([]);
    const [salesPersons3, setSalesPersons3] = useState([]);
    const [salesManagers3, setSalesManagers3] = useState([]);
    const [salesHeads3, setSalesHeads3] = useState([]);
    const [salesPersonSelected, setSalesPersonSelected] = useState("");
    const [salesManagerSelected, setSalesManagerSelected] = useState("");
    const [salesHeadSelected, setSalesHeadSelected] = useState("");
    const [salesPersonSelected2, setSalesPersonSelected2] = useState("");
    const [salesManagerSelected2, setSalesManagerSelected2] = useState("");
    const [salesHeadSelected2, setSalesHeadSelected2] = useState("");
    const [salesPersonSelected3, setSalesPersonSelected3] = useState("");
    const [salesManagerSelected3, setSalesManagerSelected3] = useState("");
    const [salesHeadSelected3, setSalesHeadSelected3] = useState("");
    useEffect(() => {
        asyncFunlocationFilterDetails();
        asyncFunDoctorSpecializationDetails();
        asyncFunLocationBindDetails();
        asyncFunDoctortitleMaster();
        GetSAPStates("1");
        jsonrow = {
            "DoctorId": "0",
            "DoctorRefCatId": "1"
        }
        jsonArr.push(jsonrow);
        if (tblRefDoctor == null) {
            SettblRefDoctor(jsonArr);
        }
        BindDoctorDetailByDoctorId();
        GetSalesHierarchy();
    }, []);

    const GetSalesHierarchy = async () => {
        try {
            let url = URL_Start + 'master/getsaleshierarchy'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    var itmData = data.Data;
                    glbSalesHierarchy = [];
                    itmData.map((option) => { return glbSalesHierarchy.push({ 'value': (option.Code == undefined || option.Code == null ? "" : option.Code), 'label': option.Value, 'disabled': false }); });
                }
            }).catch(function (error) { });
            setSalesPersons(glbSalesHierarchy);
            setSalesManagers(glbSalesHierarchy);
            setSalesHeads(glbSalesHierarchy);
            setSalesPersons2(glbSalesHierarchy);
            setSalesManagers2(glbSalesHierarchy);
            setSalesHeads2(glbSalesHierarchy);
            setSalesPersons3(glbSalesHierarchy);
            setSalesManagers3(glbSalesHierarchy);
            setSalesHeads3(glbSalesHierarchy);
            BindDoctorMappingDetails();
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const asyncFunDoctorSpecializationDetails = async () => {
        try {

            let url = URL_Start + 'DoctorMaster/getdoctorSpecializationDDL';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        const TypeOptionDs = [];

                        ds.map((option) => {
                            const TypeOptions = {
                                'id': option.SpecializationId,
                                'name': option.Specialization,
                                'label': option.Specialization,
                                'value': option.SpecializationId,
                                'disabled': false
                            }
                            return TypeOptionDs.push(TypeOptions);
                        });
                        SetSpecializationDLists(TypeOptionDs);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                    console.log('no record found');
                }


            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const asyncFunDoctortitleMaster = async () => {
        try {

            let url = URL_Start + 'DoctorMaster/GetDoctorTitleDDL';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        SetDrTitleDDL(ds.map((d) => (<option key={d.DrTitleId} value={d.DrTitleId}>{d.DrTitle}</option>)));
                    }
                }
                else {
                    SetDrTitleDDL([]);
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                    console.log('no record found');
                }


            }
            else {
                SetDrTitleDDL([]);
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
            // return data;
        } catch (error) {
            SetDrTitleDDL([]);
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }

    const asyncFunlocationFilterDetails = async (query) => {
        try {

            let url = URL_Start + 'DoctorMaster/getlocationfilterdetails';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { SearchTxt: query }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        const TypeOptionDs = [];

                        ds.map((option) => {
                            const TypeOptions = {
                                'id': option.CityId,
                                'name': option.FLocation
                            }
                            return TypeOptionDs.push(TypeOptions);
                        });
                        SetLocationFList(TypeOptionDs);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                    console.log('no record found');
                }


            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }


    async function BindDoctorDetailByDoctorId() {
        try {

            let url = URL_Start + 'DoctorMaster/getdoctordetailbyEditDocId';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { DoctorId: `${DoctorId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                globallocationStateList = [];
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {

                        if (ds.objBindDoc) {
                            formik.setFieldValue('DoctorId', DoctorId);
                            formik.setFieldValue('DoctorName', ds.objBindDoc.DoctorName);
                            formik.setFieldValue('MiddleName', ds.objBindDoc.MiddleName);
                            formik.setFieldValue('LastName', ds.objBindDoc.LastName);
                            formik.setFieldValue('mobile', ds.objBindDoc.Mobile);
                            formik.setFieldValue('Clinicphone', ds.objBindDoc.Landline);
                            formik.setFieldValue('Qualification', ds.objBindDoc.Qualification);
                            formik.setFieldValue('dob', ds.objBindDoc.OrgDOB);
                            formik.setFieldValue('dobAnv', ds.objBindDoc.OrgDateofAnniversary);
                            formik.setFieldValue('Landmark', ds.objBindDoc.Landmark);
                            formik.setFieldValue('clinicAddress', ds.objBindDoc.ClinicAddress);
                            formik.setFieldValue('ResAddress', ds.objBindDoc.ResidenceAddress);
                            formik.setFieldValue('Category', ds.objBindDoc.CategoryId);
                            formik.setFieldValue('DoctorTitle', ds.objBindDoc.DrTitleId);
                            formik.setFieldValue('pinCode', ds.objBindDoc.PinCode);
                            formik.setFieldValue('sapStateId', ds.objBindDoc.SAPStateId);
                            formik.setFieldValue('sapRegionId', ds.objBindDoc.SAPRegionId);
                            formik.setFieldValue('sapDistrictId', ds.objBindDoc.SAPDistrictId);
                            formik.setFieldValue('Specialization', ds.objBindDoc.SpecializationId);

                            if (ds.objBindDoc.SAPStateId !== "0") { GetSAPRegions(ds.objBindDoc.SAPStateId); } else { setSAPRegionsList([]); }
                            if (ds.objBindDoc.SAPRegionId !== "0") { GetSAPDistricts(ds.objBindDoc.SAPRegionId); } else { setSAPDistrictsList([]); }

                            // formik.values.DoctorId=ds.objBindDoc.DoctorId;
                            // formik.values.DoctorName=ds.objBindDoc.DoctorName;
                            //  formik.values.mobile=ds.objBindDoc.Mobile;
                            //  formik.values.Landline=ds.objBindDoc.Landline;
                            //  formik.values.Qualification=ds.objBindDoc.Qualification;
                            //  formik.values.dob=ds.objBindDoc.OrgDOB;
                            //  formik.values.dobAnv=ds.objBindDoc.OrgDateofAnniversary;
                            //  formik.values.clinicAddress=ds.objBindDoc.ClinicAddress;
                            //  formik.values.ResAddress=ds.objBindDoc.ResidenceAddress;

                            //  formik.values.Category=ds.objBindDoc.CategoryId;

                        }


                        if (ds.objDoctorLocationMappingList != null && ds.objDoctorLocationMappingList.length > 0) {

                            let filterStateData = ds.objDoctorLocationMappingList.filter(function (item) {
                                return item.StateId != '';

                            });
                            if (filterStateData.length > 0) {
                                var flags = [], output = [], l = filterStateData.length, i;
                                for (i = 0; i < l; i++) {
                                    if (flags[filterStateData[i].StateId]) continue;
                                    flags[filterStateData[i].StateId] = true;
                                    output.push({ "StateId": "" + filterStateData[i].StateId + "" });
                                }
                                globallocationStateList = output;

                            }

                            let filterRegionData = ds.objDoctorLocationMappingList.filter(function (item) {
                                return item.RegionId != '';

                            });
                            if (filterRegionData.length > 0) {
                                var flags1 = [], output1 = [], l1 = filterRegionData.length, i1;
                                for (i1 = 0; i1 < l1; i1++) {
                                    if (flags1[filterRegionData[i1].RegionId]) continue;
                                    flags1[filterRegionData[i1].RegionId] = true;
                                    output1.push({ "RegionId": "" + filterRegionData[i1].RegionId + "" });
                                }
                                SetLGlbLocationRegionList(LGlbLocationRegionList => [...LGlbLocationRegionList, ...output1]);
                            }
                            let filterDistrictData = ds.objDoctorLocationMappingList.filter(function (item) {
                                return item.DistrictId != '';

                            });

                            isStateCheckedRender.current = true;
                            asyncFunRegionLocationBindDetails(true);
                            isCheckedRender.current = true;

                            if (filterDistrictData.length > 0) {
                                var flags2 = [], output2 = [], l2 = filterDistrictData.length, i2;
                                for (i2 = 0; i2 < l2; i2++) {
                                    if (flags2[filterDistrictData[i2].DistrictId]) continue;
                                    flags2[filterDistrictData[i2].DistrictId] = true;
                                    output2.push({ "DistrictId": "" + filterDistrictData[i2].DistrictId + "" });
                                }
                                SetLGLocationDistrictList(LGLocationDistrictList => [...LGLocationDistrictList, ...output2]);


                            }
                        }
                        else {
                            globallocationStateList = [];
                        }
                        //  const value = (ds.objDoctorLocationMappingList.length > 0) ?ds.objDoctorLocationMappingList[0].FMLocation : '';
                        //     formik.values.Location=value;

                        if (ds.objDoctorSpecializationmappingType != null && ds.objDoctorSpecializationmappingType.length > 0) {

                            const TypeOptEdit1 = [];

                            ds.objDoctorSpecializationmappingType.map((item) => {
                                const TypeOptrowsEdit1 = {
                                    'SpecializationId': item.SpecializationId,
                                    'DoctorId': DoctorId
                                }
                                return TypeOptEdit1.push(TypeOptrowsEdit1);
                            });
                            SetSpecializationTyOption(TypeOptEdit1);
                        }

                        if (ds.objDoctorSpecializationmappingType != null && ds.objDoctorSpecializationmappingType.length > 0) {

                            const EditTypeOptionDsSp = [];
                            for (let i = 0; i < ds.objDoctorSpecializationmappingType.length; i++) {


                                const editTypeOptionsSp = {
                                    'id': ds.objDoctorSpecializationmappingType[0].SpecializationId,
                                    'name': ds.objDoctorSpecializationmappingType[0].DocSpecialization,
                                    'label': ds.objDoctorSpecializationmappingType[0].DocSpecialization,
                                    'value': ds.objDoctorSpecializationmappingType[0].SpecializationId,
                                    'disabled': false
                                }

                                SetSelectSpecializationD(editTypeOptionsSp);
                            }
                            ds.objDoctorSpecializationmappingType.map((option) => {

                                //    SetEditSpecializationDListsD({
                                //     'id':option.SpecializationId,
                                //     'name':option.DocSpecialization,
                                //     'label':option.Specialization,
                                //  'value':option.SpecializationId,
                                //   'disabled':false
                                // });
                                //return EditTypeOptionDsSp.push(editTypeOptionsSp);
                            });



                            //SetEditSpecializationDLists(EditTypeOptionDsSp);

                            const value1 = ds.objDoctorSpecializationmappingType[0].SpecializationId;
                            //formik.values.Specialization=value1;
                            formik.setFieldValue('Specialization', value1);


                        }


                    }
                }
                else {
                    globallocationStateList = [];
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                    setIsLoading(false);
                }

            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
            // return data;
        } catch (error) {
            if (error.response) {
                ErrorFunction(error.response.status);
                setIsLoading(false);
            }

        }
    }

    async function BindDoctorMappingDetails() {
        setIsButtonLoading(true);
        let url = URL_Start + 'DoctorMaster/getdoctorbusinessmappingdetails/' + `${DoctorId}`
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data == null) {
                    setDoctorBusinessMappingData([]);
                    setSalesPersonSelected("");
                    setSalesManagerSelected("");
                    setSalesHeadSelected("");
                    setSalesPersonSelected2("");
                    setSalesManagerSelected2("");
                    setSalesHeadSelected2("");
                    setSalesPersonSelected3("");
                    setSalesManagerSelected3("");
                    setSalesHeadSelected3("");
                    setEXID("0");
                    // formik.values.Modality1SalesPersonEmpCode = "";
                    // formik.values.Modality1SalesManagerEmpCode = "";
                    // formik.values.Modality1SalesHeadEmpCode = "";
                    // formik.values.Modality2SalesPersonEmpCode = "";
                    // formik.values.Modality2SalesManagerEmpCode = "";
                    // formik.values.Modality2SalesHeadEmpCode = "";
                    // formik.values.Modality3SalesPersonEmpCode = "";
                    // formik.values.Modality3SalesManagerEmpCode = "";
                    // formik.values.Modality3SalesHeadEmpCode = "";
                }
                else {
                    setDoctorBusinessMappingData(data.Data);
                    setSalesPersonSelected(data.Data[0].Modality1SalesPersonEmpCode);
                    setSalesManagerSelected(data.Data[0].Modality1SalesManagerEmpCode);
                    setSalesHeadSelected(data.Data[0].Modality1SalesHeadEmpCode);
                    setSalesPersonSelected2(data.Data[0].Modality2SalesPersonEmpCode);
                    setSalesManagerSelected2(data.Data[0].Modality2SalesManagerEmpCode);
                    setSalesHeadSelected2(data.Data[0].Modality2SalesHeadEmpCode);
                    setSalesPersonSelected3(data.Data[0].Modality3SalesPersonEmpCode);
                    setSalesManagerSelected3(data.Data[0].Modality3SalesManagerEmpCode);
                    setSalesHeadSelected3(data.Data[0].Modality3SalesHeadEmpCode);
                    setEXID(data.Data[0].ExcelID);
                    // formik.values.Modality1SalesPersonEmpCode = data.Data[0].Modality1SalesPersonEmpCode;
                    // formik.values.Modality1SalesManagerEmpCode = data.Data[0].Modality1SalesManagerEmpCode;
                    // formik.values.Modality1SalesHeadEmpCode = data.Data[0].Modality1SalesHeadEmpCode;
                    // formik.values.Modality2SalesPersonEmpCode = data.Data[0].Modality2SalesPersonEmpCode;
                    // formik.values.Modality2SalesManagerEmpCode = data.Data[0].Modality2SalesManagerEmpCode;
                    // formik.values.Modality2SalesHeadEmpCode = data.Data[0].Modality2SalesHeadEmpCode;
                    // formik.values.Modality3SalesPersonEmpCode = data.Data[0].Modality3SalesPersonEmpCode;
                    // formik.values.Modality3SalesManagerEmpCode = data.Data[0].Modality3SalesManagerEmpCode;
                    // formik.values.Modality3SalesHeadEmpCode = data.Data[0].Modality3SalesHeadEmpCode;
                }
                setIsButtonLoading(false);
            }
            else {
                setDoctorBusinessMappingData([]);
                setSalesPersonSelected("");
                setSalesManagerSelected("");
                setSalesHeadSelected("");
                setSalesPersonSelected2("");
                setSalesManagerSelected2("");
                setSalesHeadSelected2("");
                setSalesPersonSelected3("");
                setSalesManagerSelected3("");
                setSalesHeadSelected3("");
                setEXID("0");
                // formik.values.Modality1SalesPersonEmpCode = "";
                // formik.values.Modality1SalesManagerEmpCode = "";
                // formik.values.Modality1SalesHeadEmpCode = "";
                // formik.values.Modality2SalesPersonEmpCode = "";
                // formik.values.Modality2SalesManagerEmpCode = "";
                // formik.values.Modality2SalesHeadEmpCode = "";
                // formik.values.Modality3SalesPersonEmpCode = "";
                // formik.values.Modality3SalesManagerEmpCode = "";
                // formik.values.Modality3SalesHeadEmpCode = "";
                SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsButtonLoading(false);
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsButtonLoading(false); });
    }

    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }

    /********** Modals  ***********/
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)
    }

    const [errorModal, setErrorModal] = useState();
    const errorHandler = () => {

        formik.handleSubmit();
        // const errors = {};
        // formik.errors=errors;
        setModal(true); setErrorModal(true);

        // if((formik.touched.Location && formik.errors.Location)||(formik.touched.Location && formik.errors.Location)||(formik.touched.Location==undefined && formik.values.Location=="")
        // ||(formik.touched.Specialization && formik.errors.Specialization)||(formik.touched.Specialization && formik.errors.Specialization)||(formik.touched.Specialization==undefined && formik.values.Specialization=="")
        // )
        // {
        //     setModal(true);   
        //     setErrorModal(true); 
        // }



        // setModal(true); setErrorModal(true);

        //   if((formik.touched.purpose && formik.errors.purpose)||(formik.touched.purpose==undefined && formik.values.purpose==""))
        //   {

        //   toggleModal();
        //   setErrorModal(true); 
        //   setEditModal(false); 
        //   }

        // setModal(true);
        // setErrorModal(true);
        // setEditModal(false);
    }



    /********** Validations  ***********/
    const formik = useFormik({
        initialValues: {
            DoctorId: '0',
            // email: '',\
            DoctorTitle: '0',
            DoctorName: '',
            MiddleName: '',
            LastName: '',
            pinCode: '',
            sapStateId: '0',
            sapRegionId: '0',
            sapDistrictId: '0',
            Clinicphone: '',
            mobile: '',
            dob: '',
            dobAnv: '',
            Landmark: '',
            clinicAddress: '',
            ResAddress: '',
            Specialization: '',
            Category: '0',
            Modality1SalesPersonEmpCode: '',
            Modality1SalesManagerEmpCode: '',
            Modality1SalesHeadEmpCode: '',
            Modality2SalesPersonEmpCode: '',
            Modality2SalesManagerEmpCode: '',
            Modality2SalesHeadEmpCode: '',
            Modality3SalesPersonEmpCode: '',
            Modality3SalesManagerEmpCode: '',
            Modality3SalesHeadEmpCode: ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            // email: Yup.string().email('Invalid email address').required('Required'),
            // callType: Yup.string().transform(v => v=== '0' ? '' : v)
            // .required('Call Type is Required'),
            DoctorName: Yup.string().required('First Name is Required').matches(/^[A-Za-z]+$/, "First Name Only alphabets are allowed and space not allowed."),
            MiddleName: Yup.string().matches(/^[A-Za-z_ ]+$/, "Middle Name Only alphabets and space are allowed and special characters not allowed."),
            LastName: Yup.string().matches(/^[A-Za-z]+$/, "Last Name Only alphabets are allowed and space not allowed."),
            Specialization: Yup.string()
                .required('Specialization is Required'),
            sapStateId: Yup.string().transform(v => v === '0' ? '' : v).required('SAP State name Selection Required'),
            sapRegionId: Yup.string().transform(v => v === '0' ? '' : v).required('SAP Region name Selection Required'),
            sapDistrictId: Yup.string().transform(v => v === '0' ? '' : v).required('SAP District name Selection Required')
        }),
        isSubmitting: true,
        onSubmit: async (values, actions) => {
            try {
                if (globallocationStateList.length == 0) {
                    setModal(true); setErrorModal(true);
                }
                else if (LocationRegionList.length > 0 && LGlbLocationRegionList.length == 0) {
                    setModal(true); setErrorModal(true);
                }
                else if (LocationDistrictList.length > 0 && LGLocationDistrictList.length == 0) {
                    setModal(true); setErrorModal(true);
                }
                else {
                    setModal(false);
                    setErrorModal(false);
                    setErrorPopUp("");

                    setIsFinalButtonLoading(true);

                    let url = URL_Start + 'DoctorMaster/SETInsertUpdateDoctorDetails';

                    let options = {
                        method: 'POST',
                        url: url,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json;charset=UTF-8'
                        },
                        data: {
                            DoctorId: values.DoctorId, DrTitleId: values.DoctorTitle, DoctorName: values.DoctorName,
                            MiddleName: values.MiddleName, LastName: values.LastName, PinCode: values.pinCode,
                            SAPStateId: values.sapStateId, SAPRegionId: values.sapRegionId, SAPDistrictId: values.sapDistrictId,
                            SAPCityId: values.SAPCityId, Landmark: values.Landmark, SpecializationId: values.Specialization,
                            ClinicAddress: values.clinicAddress, ResidenceAddress: values.ResAddress,
                            Landline: values.Clinicphone, Mobile: values.mobile,
                            DOB: values.dob, DateofAnniversary: values.dobAnv, CategoryId: values.Category, ToMappedMrkUserId: '0',
                            AddedBy: `${LoginId}`, objDoctorRefftypeList: tblRefDoctor, objTblLocationZoneIds: LGLocationZoneList,
                            objTblLocationStateIds: globallocationStateList, objTblLocationRegionIds: LGlbLocationRegionList, objTblLocationDistrictIds: LGLocationDistrictList,
                            objtblDoctorSpecializationmappingType: SpecializationTyOption,
                            M1Person: salesPersonSelected,//values.Modality1SalesPersonEmpCode,
                            M1Manager: salesManagerSelected,//values.Modality1SalesManagerEmpCode,
                            M1Head: salesHeadSelected,//values.Modality1SalesHeadEmpCode,
                            M2Person: salesPersonSelected2,//values.Modality2SalesPersonEmpCode,
                            M2Manager: salesManagerSelected2,//values.Modality2SalesManagerEmpCode,
                            M2Head: salesHeadSelected2,//values.Modality2SalesHeadEmpCode,
                            M3Person: salesPersonSelected3,//values.Modality3SalesPersonEmpCode,
                            M3Manager: salesManagerSelected3,//values.Modality3SalesManagerEmpCode,
                            M3Head: salesHeadSelected3,//values.Modality3SalesHeadEmpCode,
                            ExcelId: exid
                        }
                    };
                    let response = await axios(options);
                    let responseOK = response && response.status == 200;
                    if (responseOK) {
                        setIsFinalButtonLoading(false);
                        let data = response.data;
                        // let data = await response.data;
                        if (data.Success == true && data.Data == "2000") {
                            formik.resetForm();


                            SweetAlert.fire({ title: "Success!", text: "Doctor has been Updated!", icon: "success" });
                            setErrorModal(false);
                            history.push({ pathname: `${process.env.PUBLIC_URL}/doctor-master/doctors`, search: `` });

                        }
                        else if (data.Success == true && data.Data == "2001") {
                            formik.resetForm();
                            SweetAlert.fire({ title: "Warning", text: "Doctor Already Exist!", icon: "info" });
                            setErrorModal(false);
                        }
                        else if (data.Success == true && data.Data == "2002") {
                            //formik.resetForm();
                            SweetAlert.fire({ title: "Warning", text: "Invalid Modality1SalesPersonEmpCode! Please enter valid code", icon: "info" });
                            setErrorModal(false);
                        }
                        else if (data.Success == true && data.Data == "2003") {
                            //formik.resetForm();
                            SweetAlert.fire({ title: "Warning", text: "Invalid Modality1SalesManagerEmpCode! Please enter valid code", icon: "info" });
                            setErrorModal(false);
                        }
                        else if (data.Success == true && data.Data == "2004") {
                            //formik.resetForm();
                            SweetAlert.fire({ title: "Warning", text: "Invalid Modality1SalesHeadEmpCode! Please enter valid code", icon: "info" });
                            setErrorModal(false);
                        }
                        else if (data.Success == true && data.Data == "2005") {
                            //formik.resetForm();
                            SweetAlert.fire({ title: "Warning", text: "Invalid Modality2SalesPersonEmpCode! Please enter valid code", icon: "info" });
                            setErrorModal(false);
                        }
                        else if (data.Success == true && data.Data == "2006") {
                            //formik.resetForm();
                            SweetAlert.fire({ title: "Warning", text: "Invalid Modality2SalesManagerEmpCode! Please enter valid code", icon: "info" });
                            setErrorModal(false);
                        }
                        else if (data.Success == true && data.Data == "2007") {
                            //formik.resetForm();
                            SweetAlert.fire({ title: "Warning", text: "Invalid Modality2SalesHeadEmpCode! Please enter valid code", icon: "info" });
                            setErrorModal(false);
                        }
                        else if (data.Success == true && data.Data == "2008") {
                            //formik.resetForm();
                            SweetAlert.fire({ title: "Warning", text: "Invalid Modality3SalesPersonEmpCode! Please enter valid code", icon: "info" });
                            setErrorModal(false);
                        }
                        else if (data.Success == true && data.Data == "2009") {
                            //formik.resetForm();
                            SweetAlert.fire({ title: "Warning", text: "Invalid Modality3SalesManagerEmpCode! Please enter valid code", icon: "info" });
                            setErrorModal(false);
                        }
                        else if (data.Success == true && data.Data == "2010") {
                            //formik.resetForm();
                            SweetAlert.fire({ title: "Warning", text: "Invalid Modality3SalesHeadEmpCode! Please enter valid code", icon: "info" });
                            setErrorModal(false);
                        }
                        else {
                            if (data.ErrorList != null && data.ErrorList.length > 0) {
                                let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                                SweetAlert.fire({ title: "Error", text: data.ErrorList[0].errorMsg, icon: "error" });
                            }

                        }


                    }
                    else {
                        console.log('no record found');
                        setIsFinalButtonLoading(false);
                    }
                }

            } catch (error) {
                console.log(error.message);
                setIsFinalButtonLoading(false);
                let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
            }
            setTimeout(() => {
                setIsFinalButtonLoading(false);
                actions.setSubmitting(false);
            }, 1000);
        },
    });


    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }





    const PrevBckHandler = async () => {
        history.push({ pathname: `${process.env.PUBLIC_URL}/doctor-master/doctors`, search: `` });
    }
    const SpecializationBlurHandler = (e) => {
        if (formik.values.Location == '')
            formik.setFieldTouched('Specialization', true);


    }
    const SpecializationChangeHandler = (option) => {
        SetSelectSpecializationD(option);
        const valuename = (option != null) ? option.name : '';
        const idval = (option != null) ? option.value : '';
        //const value = (option.length > 0) ?option[0].name : '';
        //const idval = (option.length > 0) ?option[0].id : '';
        formik.setFieldValue('Specialization', idval);
        //formik.setFieldValue('Users', value);
        const TypeOpt = [];
        const TypeOptrows = {
            'SpecializationId': idval,
            'DoctorId': DoctorId
        }
        TypeOpt.push(TypeOptrows);
        // option.map((item) =>{
        //               const TypeOptrows={
        //                   'SpecializationId':item.id,
        //                 'DoctorId':'0'
        //               }
        //    return TypeOpt.push(TypeOptrows);
        //       });
        //               console.log(TypeOpt);

        SetSpecializationTyOption(TypeOpt);

    }
    //   const SpecializationChangeHandler= (option) => {
    //     const TypeOpt1=[];
    //     if(option.length>0)
    //     {
    //         option.map((item) =>{
    //             const TypeOptrows1={
    //                 'id':item.id,
    //                 'name':item.name
    //             }
    //          return TypeOpt1.push(TypeOptrows1);
    //             });

    //     }
    //     SetEditSpecializationDLists(TypeOpt1);  
    //     const value = (option.length > 0) ?option[0].name : '';
    //     const idval = (option.length > 0) ?option[0].id : '';
    //     formik.setFieldValue('Specialization', idval);
    //     //formik.setFieldValue('Users', value);
    //     const TypeOpt=[];

    //         option.map((item) =>{
    //                       const TypeOptrows={
    //                           'SpecializationId':item.id,
    //                         'DoctorId':DoctorId
    //                       }
    //                    return TypeOpt.push(TypeOptrows);
    //                       });
    //                       console.log(TypeOpt);

    //                       SetSpecializationTyOption(TypeOpt);  

    // }
    const asyncFunLocationBindDetails = async () => {
        try {

            let url = URL_Start + 'master/getstateslist/' + `${LocationZoneId}`;
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        SetLocationStateList(ds);
                    }
                    else {
                        SetLocationStateList([]);
                    }

                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                    console.log('no record found');
                }
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }

    const asyncFunRegionLocationBindDetails = async (isChecked) => {
        try {

            let url = URL_Start + 'master/getBindLocationRegionDetails'
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { objTblLocationTypeList: globallocationStateList }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    SetLocationRegionList([]);
                    if (ds != null && ds.length > 0) {
                        SetLocationRegionList(ds);

                    }
                    else {
                        SetLocationRegionList([]);
                    }

                    if (ds != null && ds.length > 0) {
                        if (isChecked == false) {
                            let result = LGlbLocationRegionList.filter(o1 => ds.some(o2 => o1.RegionId === o2.RegionId));
                            SetLGlbLocationRegionList(LGlbLocationRegionList => [...LGlbLocationRegionList.filter(o1 => ds.some(o2 => o1.RegionId == o2.RegionId))]);

                        }
                        //    else{
                        //     let result = LGlbLocationRegionList.filter(o1 => ds.some(o2 => o1.RegionId === o2.RegionId));
                        //     SetLGlbLocationRegionList(LGlbLocationRegionList=>[...LGlbLocationRegionList.filter(o1 => ds.some(o2 => o1.RegionId === o2.RegionId))]);


                        //    }
                        //     GlbLocationRegionList.map((item1) =>{
                        //     //     if(GlbLocationRegionList.map(val => val["RegionId"]).indexOf(item1["RegionId"]) == -1)
                        //     //     {
                        //     //         const item = {
                        //     //             "RegionId":item1.RegionId
                        //     //           };
                        //     //   return globaltblExistGroups.push(item);    
                        //     //     }
                        //     GlbLocationRegionList=ds.filter((d) => d.RegionId===item1.RegionId);
                        //         //GlbLocationRegionList=GlbLocationRegionList.filter((d) => d.RegionId !=item.RegionId);
                        //GlbLocationRegionList=result;

                        //  });
                    }
                    else {
                        SetLGlbLocationRegionList([]);
                    }
                    console.log(JSON.stringify(LGlbLocationRegionList));
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                    console.log('no record found');
                }
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
        } catch (error) {
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }

    const asyncFunDistinctLocationBindDetails = async (isChecked) => {
        try {

            let url = URL_Start + 'master/getBindLocationDistrictDetails'
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { objTblLocationTypeList: LGlbLocationRegionList }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    SetLocationDistrictList([]);
                    if (ds != null && ds.length > 0) {
                        SetLocationDistrictList(ds);

                    }
                    else {
                        SetLocationDistrictList([]);
                    }

                    if (ds != null && ds.length > 0) {
                        if (isChecked == false) {
                            SetLGLocationDistrictList(LGLocationDistrictList => [...LGLocationDistrictList.filter(o1 => ds.some(o2 => o1.DistrictId == o2.DistrictId))]);

                        }
                    }
                    else {
                        SetLGLocationDistrictList([]);
                    }
                    isFirstRender.current = false;
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                    console.log('no record found');
                }
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
        } catch (error) {
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const StateCheckHandler = (e) => {
        const value = e.target.value;
        const isChecked = e.target.checked;
        const TypeOptDocL = [];
        const item = {
            "StateId": value
        };

        if ((globallocationStateList.map(val => val["StateId"]).indexOf(item["StateId"]) == -1)) {
            globallocationStateList.push(item);
        }
        if (isChecked == false) {
            globallocationStateList = globallocationStateList.filter((d) => d.StateId != item.StateId);
        }
        isStateCheckedRender.current = true;
        asyncFunRegionLocationBindDetails(isChecked);
        isCheckedRender.current = isChecked;
    }
    useEffect(() => {
        console.log('Test');
    }, [LocationStateSection]);

    const RegionCheckHandler = (e) => {
        const value = e.target.value;
        const isChecked = e.target.checked;
        const TypeOptDocL = [];
        const item = {
            "RegionId": value
        };

        if ((LGlbLocationRegionList.map(val => val["RegionId"]).indexOf(item["RegionId"]) == -1)) {
            TypeOptDocL.push(item);
            SetLGlbLocationRegionList(LGlbLocationRegionList => [...LGlbLocationRegionList, ...TypeOptDocL]);
        }
        if (isChecked == false) {
            //GlbLocationRegionList=GlbLocationRegionList.filter((d) => d.RegionId !=item.RegionId);
            SetLGlbLocationRegionList(LGlbLocationRegionList => [...LGlbLocationRegionList.filter((d) => d.RegionId != item.RegionId)]);
            let checkRegionId = `chkRegion${value}`;
            document.getElementById("" + checkRegionId + "").checked = false;
        }
        isFirstRender.current = true;
        isCheckedRender.current = isChecked;

    }
    useEffect(() => {
        console.log(LGlbLocationRegionList);
        if (isFirstRender.current == true) {
            let IsCheckStatus = isCheckedRender.current;
            asyncFunDistinctLocationBindDetails(IsCheckStatus);
        }
        if (isStateCheckedRender.current == true) {
            let IsCheckStatus = isCheckedRender.current;
            asyncFunDistinctLocationBindDetails(IsCheckStatus);
        }


    }, [LGlbLocationRegionList, globallocationStateList]);

    const DistrictCheckHandler = (e) => {
        const value = e.target.value;
        const isChecked = e.target.checked;
        const TypeOptDocL = [];
        const item = {
            "DistrictId": value
        };

        if ((LGLocationDistrictList.map(val => val["DistrictId"]).indexOf(item["DistrictId"]) == -1)) {
            TypeOptDocL.push(item);
            SetLGLocationDistrictList(LGLocationDistrictList => ([...LGLocationDistrictList, ...TypeOptDocL]));
        }
        if (isChecked == false) {
            SetLGLocationDistrictList(LGLocationDistrictList => [...LGLocationDistrictList.filter((d) => d.DistrictId != item.DistrictId)]);
            let checkRegionId = `chkDistrict${value}`;
            document.getElementById("" + checkRegionId + "").checked = false;
        }

    }
    const GetPincodeLocation = async (e) => {
        let text = e.target.value;
        let length = text.length;
        if (length === 6) {
            try {
                let url = URL_Start + 'master/getpincodelocations/' + e.target.value
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        if (data != null) {
                            const pinCodeData = data.Data;
                            formik.values.sapStateId = pinCodeData[0].SAPStateId;
                            GetSAPRegions(formik.values.sapStateId);
                            formik.values.sapRegionId = pinCodeData[0].SAPRegionId;
                            GetSAPDistricts(formik.values.sapRegionId);
                            formik.values.sapDistrictId = pinCodeData[0].SAPDistrictId;
                        }
                        else {
                            formik.values.sapStateId = "";
                            formik.values.sapRegionId = "";
                            formik.values.sapDistrictId = "";
                        }
                    }
                    else {
                        formik.values.sapStateId = "";
                        formik.values.sapRegionId = "";
                        formik.values.sapDistrictId = "";
                    }
                }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
            }
            catch (error) {
                SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
            }
        }
        else {
            formik.values.sapStateId = "";
            formik.values.sapRegionId = "";
            formik.values.sapDistrictId = "";
        }
    }
    const GetSAPStates = async (id) => {
        try {
            let url = URL_Start + 'master/getstateslist/' + id
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setSAPStatesList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSAPRegions = async (id) => {
        try {
            let url = URL_Start + 'master/getregionslist/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data !== null) { setSAPRegionsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
                    else { setSAPRegionsList([]); }
                }
                else { setSAPRegionsList([]); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSAPDistricts = async (id) => {
        try {
            let url = URL_Start + 'master/getdistrictslist/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data !== null) { setSAPDistrictsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
                    else { setSAPDistrictsList([]); }
                }
                else { setSAPDistrictsList([]); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const SAPStateChangeHandler = (e) => {
        formik.values.sapRegionId = "0";
        formik.values.sapDistrictId = "0";
        formik.values.isSubCentre = false;
        if (e.target.value !== "0") { GetSAPRegions(e.target.value); } else { setSAPRegionsList([]); }
        setSAPDistrictsList([]);
    }
    const SAPRegionChangeHandler = (e) => {
        formik.values.sapDistrictId = "0";
        if (e.target.value !== "0") { GetSAPDistricts(e.target.value); } else { setSAPDistrictsList([]); }
    }
    const SalesPersonsChangeHandler = (info) => {
        formik.values.Modality1SalesPersonEmpCode = info.value;
        setSalesPersonSelected(info.value);
    }
    const SalesManagersChangeHandler = (info) => {
        formik.values.Modality1SalesManagerEmpCode = info.value;
        setSalesManagerSelected(info.value);
    }
    const SalesHeadsChangeHandler = (info) => {
        formik.values.Modality1SalesHeadEmpCode = info.value;
        setSalesHeadSelected(info.value);
    }

    const SalesPersonsChangeHandler2 = (info) => {
        formik.values.Modality2SalesPersonEmpCode = info.value;
        setSalesPersonSelected2(info.value);
    }
    const SalesManagersChangeHandler2 = (info) => {
        formik.values.Modality2SalesManagerEmpCode = info.value;
        setSalesManagerSelected2(info.value);
    }
    const SalesHeadsChangeHandler2 = (info) => {
        formik.values.Modality2SalesHeadEmpCode = info.value;
        setSalesHeadSelected2(info.value);
    }

    const SalesPersonsChangeHandler3 = (info) => {
        formik.values.Modality3SalesPersonEmpCode = info.value;
        setSalesPersonSelected3(info.value);
    }
    const SalesManagersChangeHandler3 = (info) => {
        formik.values.Modality3SalesManagerEmpCode = info.value;
        setSalesManagerSelected3(info.value);
    }
    const SalesHeadsChangeHandler3 = (info) => {
        formik.values.Modality3SalesHeadEmpCode = info.value;
        setSalesHeadSelected3(info.value);
    }

    const filterBy = () => true;
    return (
        <Fragment>
            <Breadcrumb title="Doctors" parent="Master" />
            <Container fluid>

                <Row>
                    <Col md={12}>
                        <Card>
                            <div class="pb-0 card-header"><div class="card-title h5">Basic Detail</div></div>
                            <Card.Body>

                                <Form.Row className="justify-content-center">

                                    <Col md={2} lg={3}>
                                        <Form.Group>
                                            <Form.Label htmlFor="DoctorTitle">Title</Form.Label>
                                            <Form.Control as="select" name="DoctorTitle" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.DoctorTitle} >
                                                <option value="0">---Select---</option>
                                                {DrTitleDDL}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} lg={3}>
                                        <Form.Group>
                                            <Form.Label htmlFor="DoctorName">First Name <span className='text-red'>*</span></Form.Label>
                                            <Form.Control type='text' id="DoctorName" name="DoctorName"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.DoctorName} />
                                            {formik.touched.DoctorName && formik.errors.DoctorName ? (
                                                <div className='text-required'>{formik.errors.DoctorName}</div>
                                            ) : null}

                                        </Form.Group>
                                    </Col>
                                    <Col md={3} lg={3}>
                                        <Form.Group>
                                            <Form.Label htmlFor="MiddleName">Middle Name</Form.Label>
                                            <Form.Control type='text' id="MiddleName" name="MiddleName"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.MiddleName} />
                                            {formik.touched.MiddleName && formik.errors.MiddleName ? (
                                                <div className='text-required'>{formik.errors.MiddleName}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} lg={3}>
                                        <Form.Group>
                                            <Form.Label htmlFor="Last Name">Last Name</Form.Label>
                                            <Form.Control type='text' id="LastName" name="LastName"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.LastName} />

                                            {formik.touched.LastName && formik.errors.LastName ? (
                                                <div className='text-required'>{formik.errors.LastName}</div>
                                            ) : null}

                                        </Form.Group>
                                    </Col>


                                </Form.Row>
                                <Form.Row className="justify-content-center">

                                    <Col md={3} lg={3}>
                                        <Form.Group>
                                            <Form.Label>Pin Code</Form.Label>
                                            <Form.Control type="text" name="pinCode" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.pinCode} onKeyUp={(e) => GetPincodeLocation(e, "1")} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} lg={3}>
                                        <Form.Group>
                                            <Form.Label>SAP State<span className='text-required'>*</span></Form.Label>
                                            <Form.Control as="select" name="sapStateId" onChangeCapture={SAPStateChangeHandler} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.sapStateId}>
                                                <option value="">---Select---</option>
                                                {sapStatesList}
                                            </Form.Control>
                                            {formik.touched.sapStateId && formik.errors.sapStateId ? (<div className='text-required'>{formik.errors.sapStateId}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} lg={3}>
                                        <Form.Group>
                                            <Form.Label>SAP Region<span className='text-required'>*</span></Form.Label>
                                            <Form.Control as="select" name="sapRegionId" onChangeCapture={SAPRegionChangeHandler} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.sapRegionId}>
                                                <option value="">---Select---</option>
                                                {sapRegionsList}
                                            </Form.Control>
                                            {formik.touched.sapRegionId && formik.errors.sapRegionId ? (<div className='text-required'>{formik.errors.sapRegionId}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} lg={3}>
                                        <Form.Group>
                                            <Form.Label>SAP District<span className='text-required'>*</span></Form.Label>
                                            <Form.Control as="select" name="sapDistrictId" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.sapDistrictId}>
                                                <option value="">---Select---</option>
                                                {sapDistrictsList}
                                            </Form.Control>
                                            {formik.touched.sapDistrictId && formik.errors.sapDistrictId ? (<div className='text-required'>{formik.errors.sapDistrictId}</div>) : null}
                                        </Form.Group>
                                    </Col>

                                </Form.Row>

                                {/* <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                       <Form.Group>
                                                <Form.Label>Location</Form.Label>
                                                <AsyncTypeahead
                                                    id="Location" name="Location"
                                                    //clearButton
                                                    defaultSelected={LocationFList.slice(0, 5)}
                                                    labelKey="name"
                                                    multiple
                                                    minLength={3}
                                                    ref={typeahead}
                                                    onSearch={asyncFunlocationFilterDetails}
                                                    options={LocationFList}
                                                    value={formik.values.Location}
                                                     // onChange is on valid option selection (a menu click)
  onChange={option => {
      const value = (option.length > 0) ?option[0].name : '';
      formik.setFieldValue('Location', value);
      const TypeOpt=[];
                      
          option.map((item) =>{
                        const TypeOptrows={
                            'CityId':item.id,
                            'DoctorId':'0'
                        }
                     return TypeOpt.push(TypeOptrows);
                        });
                        SetLocationTyOption(TypeOpt);  
     // SetExecutiveTyOption(option);
   
  }}
 
  // onBlur is on leaving the control. Can be used for error checks
  onBlur={(e) => {
       if(formik.values.Location=='' || formik.values.Location==null)
   formik.setFieldTouched('Location',true);
    else
    formik.errors.Location="";

  }}
                                                    placeholder="Searching....."
                                                />
                                                 {formik.touched.Location && formik.errors.Location ? (
                                                    <div className='text-required'>{formik.errors.Location}</div>
                                                ) : null}
                                            </Form.Group>
                                       </Col>
                                   </Form.Row> */}


                                <Form.Row className="justify-content-center  mt15">

                                    <Col md={3} lg={3}>
                                        <Form.Group>
                                            <Form.Label htmlFor="Clinicphone">Clinic phone</Form.Label>
                                            <Form.Control type='text' id="Clinicphone" name="Clinicphone"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.Clinicphone} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} lg={3}>
                                        <Form.Group>
                                            <Form.Label htmlFor="mobile">Mobile</Form.Label>
                                            <Form.Control type='text' id="mobile" name="mobile"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.mobile} />

                                            {formik.touched.mobile && formik.errors.mobile ? (
                                                <div className='text-required'>{formik.errors.mobile}</div>
                                            ) : null}

                                        </Form.Group>
                                    </Col>
                                    <Col md={3} lg={3}>
                                        <Form.Group className="mb-3 align-items-center">
                                            <Form.Label>Date of Birth</Form.Label>
                                            <Form.Control type="date" name="dob"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.dob}
                                                ref={calendarRef}
                                            />

                                        </Form.Group>
                                    </Col>

                                    <Col md={3} lg={3}>
                                        <Form.Group className="mb-3 align-items-center">
                                            <Form.Label>Date of Anniversary</Form.Label>
                                            <Form.Control type="date" name="dobAnv"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.dobAnv}
                                            />

                                        </Form.Group>
                                    </Col>
                                    {/* <Col md={3} lg={3}>
                                           <Form.Group>
                                               <Form.Label htmlFor="Qualification">Qualification</Form.Label>
                                               <Form.Control type='text' id="Qualification" name="Qualification"
                                                   onChange={formik.handleChange}
                                                   onBlur={formik.handleBlur}
                                                   value={formik.values.Qualification} />
                                           </Form.Group>
                                       </Col> */}


                                </Form.Row>


                                <Form.Row className="justify-content-center">

                                    <Col md={4} lg={4}>
                                        <Form.Group>
                                            <Form.Label htmlFor="purpose">Landmark</Form.Label>
                                            <textarea className="form-control" id="Landmark" name="Landmark" rows="3" onChange={formik.handleChange}
                                                onBlur={formik.handleBlur} maxLength={50}
                                                value={formik.values.Landmark}></textarea>

                                        </Form.Group>
                                    </Col>
                                    <Col md={4} lg={4}>
                                        <Form.Group>
                                            <Form.Label htmlFor="purpose">Clinic Address</Form.Label>
                                            <textarea className="form-control" id="clinicAddress" name="clinicAddress" rows="3" onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.clinicAddress}></textarea>



                                        </Form.Group>
                                    </Col>
                                    <Col md={4} lg={4}>
                                        <Form.Group>
                                            <Form.Label htmlFor="ResAddress">Residence Address</Form.Label>
                                            <textarea className="form-control" id="ResAddress" name="ResAddress" rows="3" onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.ResAddress}></textarea>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>



                                <Form.Row className="justify-content-center">

                                    <Col md={4} lg={4}>
                                        <Form.Group>
                                            <Form.Label>Specialization<span className='text-required'>*</span></Form.Label>
                                            <Select id="Specialization" name="Specialization"
                                                // defaultValue={SelectSpecializationD}

                                                onChange={SpecializationChangeHandler}
                                                options={SpecializationDLists}
                                                value={SelectSpecializationD}

                                            />

                                            {/* <Typeahead
                                                    id="Specialization" name="Specialization"
                                                   single
                                                    //clearButton
                                                    //defaultSelected={SpecializationDLists.slice(0, 5)}
                                                    labelKey="name"
                                                    ref={typeaheadCurrent}
                                                    options={SpecializationDLists}
                                                    value={formik.values.Specialization}
                                                    selected={EditSpecializationDLists}
  onChange={SpecializationChangeHandler}
 
  onBlur={SpecializationBlurHandler}
                                                    placeholder="---select---"
                                                /> */}
                                            {formik.touched.Specialization && formik.errors.Specialization ? (<div className='text-required'>{formik.errors.Specialization}</div>) : null}
                                        </Form.Group>


                                    </Col>
                                    <Col md={4} lg={4}>
                                        <Form.Group>
                                            <Form.Label>Select Category</Form.Label>
                                            <Form.Control as="select" name="Category" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.Category}>
                                                <option value="0">---Select---</option>
                                                <option value="1">A</option>
                                                <option value="2">B</option>
                                                <option value="3">C</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                </Form.Row>


                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <div class="pb-0 card-header"><div class="card-title h5">Location Detail</div></div>
                            <Card.Body>
                                <div className='text-required'>*State Selection required</div>
                                <Form.Row className="justify-content-left  mt15">

                                    {LocationStateList.map((item) => {

                                        return <>{globallocationStateList.some(function (d) { return d.StateId == item.Id }) == false ? <Col md={4} lg={4}>

                                            <Form.Label>

                                                <input name={"chkState" + `${item.Id}`} className="checkbox_animated" type="checkbox"
                                                    onChange={StateCheckHandler} value={`${item.Id}`} />
                                                {item.Value}
                                            </Form.Label>

                                        </Col> : <Col md={4} lg={4}>

                                            <Form.Label>

                                                <input name={"chkState" + `${item.Id}`} className="checkbox_animated" type="checkbox"
                                                    onChange={StateCheckHandler} value={`${item.Id}`} checked />
                                                {item.Value}
                                            </Form.Label>

                                        </Col>}

                                        </>
                                    })

                                    }

                                </Form.Row>
                                {globallocationStateList.length > 0 && LocationRegionList.length > 0 && <>  <hr></hr>
                                    <div className='text-required'>*Region Selection required</div>
                                    <Form.Row className="justify-content-left  mt15">

                                        {LocationRegionList.map((item) => {

                                            return <>{LGlbLocationRegionList.some(function (d) { return d.RegionId == item.RegionId }) == false ? <Col md={4} lg={4}>

                                                <Form.Label>

                                                    <input name={"chkRegion" + `${item.RegionId}`} className="checkbox_animated" type="checkbox"
                                                        onChange={RegionCheckHandler} value={`${item.RegionId}`} id={"chkRegion" + `${item.RegionId}`} />
                                                    {item.RegionName}
                                                </Form.Label>

                                            </Col> : <Col md={4} lg={4}>
                                                <Form.Label>

                                                    <input name={"chkRegion" + `${item.RegionId}`} className="checkbox_animated" type="checkbox"
                                                        onChange={RegionCheckHandler} value={`${item.RegionId}`} checked id={"chkRegion" + `${item.RegionId}`} />
                                                    {item.RegionName}
                                                </Form.Label>

                                            </Col>}

                                            </>
                                        })

                                        }

                                    </Form.Row></>}

                                {LocationRegionList.length > 0 && LocationDistrictList.length > 0 && <>  <hr></hr>
                                    <div className='text-required'>*DistrictId Selection required</div>
                                    <Form.Row className="justify-content-left  mt15">

                                        {LocationDistrictList.map((item) => {

                                            return <>{LGLocationDistrictList.some(function (d) { return d.DistrictId == item.DistrictId }) == false ? <Col md={4} lg={4}>

                                                <Form.Label>

                                                    <input name={"chkDistrict" + `${item.DistrictId}`} className="checkbox_animated" type="checkbox"
                                                        onChange={DistrictCheckHandler} value={`${item.DistrictId}`} id={"chkDistrict" + `${item.DistrictId}`} />
                                                    {item.DistrictName}
                                                </Form.Label>

                                            </Col> : <Col md={4} lg={4}>
                                                <Form.Label>

                                                    <input name={"chkDistrict" + `${item.DistrictId}`} className="checkbox_animated" type="checkbox"
                                                        onChange={DistrictCheckHandler} value={`${item.DistrictId}`} checked id={"chkDistrict" + `${item.DistrictId}`} />
                                                    {item.DistrictName}
                                                </Form.Label>

                                            </Col>}

                                            </>
                                        })

                                        }

                                    </Form.Row></>}

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Sales Hierarchy</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Col lg={4} md={4}>
                                        <Form.Group>
                                            <Form.Label>Sales Person<span className='text-required'>*</span></Form.Label>
                                            <Select options={salesPersons} onChange={SalesPersonsChangeHandler}
                                                value={salesPersons.filter(function (x) { return x.value === salesPersonSelected; })} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} md={4}>
                                        <Form.Group>
                                            <Form.Label>Sales Manager</Form.Label>
                                            <Select options={salesManagers} onChange={SalesManagersChangeHandler}
                                                value={salesManagers.filter(function (x) { return x.value === salesManagerSelected; })} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} md={4}>
                                        <Form.Group>
                                            <Form.Label>Sales Head</Form.Label>
                                            <Select options={salesHeads} onChange={SalesHeadsChangeHandler}
                                                value={salesHeads.filter(function (i) { return i.value === salesHeadSelected; })} />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> */}

                {/* {
                    doctorbusinessmappingdata == [] || doctorbusinessmappingdata == null || doctorbusinessmappingdata.length == 0 ? null
                        :
                        <> */}
                <Row>
                    <Col md={12}>
                        <Card>
                            <div class="pb-0 card-header"><div class="card-title h5">Doctor Business Mapping</div></div>
                            <Card.Body>
                                {
                                    isButtonLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <div className='text-required'>*Pathology (Modality1)</div>
                                            <Form.Row className="justify-content-center mt-3">
                                                <Col md={4} lg={4}>
                                                    <Form.Group>
                                                        <Form.Label htmlFor="Modality1SalesPersonEmpCode">Modality1SalesPersonEmpCode</Form.Label>
                                                        <Select name="Modality1SalesPersonEmpCode" options={salesPersons} onChange={SalesPersonsChangeHandler} value={salesPersons.filter(function (x) { return x.value === salesPersonSelected; })} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4} lg={4}>
                                                    <Form.Group>
                                                        <Form.Label htmlFor="Modality1SalesManagerEmpCode">Modality1SalesManagerEmpCode</Form.Label>
                                                        <Select name="Modality1SalesManagerEmpCode" options={salesManagers} onChange={SalesManagersChangeHandler} value={salesManagers.filter(function (x) { return x.value === salesManagerSelected; })} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4} lg={4}>
                                                    <Form.Group>
                                                        <Form.Label htmlFor="Modality1SalesHeadEmpCode">Modality1SalesHeadEmpCode</Form.Label>
                                                        <Select name="Modality1SalesHeadEmpCode" options={salesHeads} onChange={SalesHeadsChangeHandler} value={salesHeads.filter(function (i) { return i.value === salesHeadSelected; })} />
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <div className='text-required'>*Radiology (Modality2)</div>
                                            <Form.Row className="justify-content-center mt-3">
                                                <Col md={4} lg={4}>
                                                    <Form.Group>
                                                        <Form.Label htmlFor="Modality2SalesPersonEmpCode">Modality2SalesPersonEmpCode</Form.Label>
                                                        <Select name="Modality2SalesPersonEmpCode" options={salesPersons2} onChange={SalesPersonsChangeHandler2} value={salesPersons2.filter(function (i) { return i.value === salesPersonSelected2; })} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4} lg={4}>
                                                    <Form.Group>
                                                        <Form.Label htmlFor="Modality2SalesManagerEmpCode">Modality2SalesManagerEmpCode</Form.Label>
                                                        <Select name="Modality2SalesManagerEmpCode" options={salesManagers2} onChange={SalesManagersChangeHandler2} value={salesManagers2.filter(function (i) { return i.value === salesManagerSelected2; })} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4} lg={4}>
                                                    <Form.Group>
                                                        <Form.Label htmlFor="Modality2SalesHeadEmpCode">Modality2SalesHeadEmpCode</Form.Label>
                                                        <Select name="Modality2SalesHeadEmpCode" options={salesHeads2} onChange={SalesHeadsChangeHandler2} value={salesHeads2.filter(function (i) { return i.value === salesHeadSelected2; })} />
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <div className='text-required'>*Nuclear Medicine (Modality3)</div>
                                            <Form.Row className="justify-content-center mt-3">
                                                <Col md={4} lg={4}>
                                                    <Form.Group>
                                                        <Form.Label htmlFor="Modality3SalesPersonEmpCode">Modality3SalesPersonEmpCode</Form.Label>
                                                        <Select name="Modality3SalesPersonEmpCode" options={salesPersons3} onChange={SalesPersonsChangeHandler3} value={salesPersons3.filter(function (i) { return i.value === salesPersonSelected3; })} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4} lg={4}>
                                                    <Form.Group>
                                                        <Form.Label htmlFor="Modality3SalesManagerEmpCode">Modality3SalesManagerEmpCode</Form.Label>
                                                        <Select name="Modality3SalesManagerEmpCode" options={salesManagers3} onChange={SalesManagersChangeHandler3} value={salesManagers3.filter(function (i) { return i.value === salesManagerSelected3; })} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4} lg={4}>
                                                    <Form.Group>
                                                        <Form.Label htmlFor="Modality3SalesHeadEmpCode">Modality3SalesHeadEmpCode</Form.Label>
                                                        <Select name="Modality3SalesHeadEmpCode" options={salesHeads3} onChange={SalesHeadsChangeHandler3} value={salesHeads3.filter(function (i) { return i.value === salesHeadSelected3; })} />
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/* </>
                } */}

                <Row className='mt15'>
                    <Col md={12} className='text-center'>
                        <Button variant='secondary' className="mr-2" onClick={PrevBckHandler} >Cancel</Button>
                        {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                    </Col>
                </Row>





            </Container>







            {errorModal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>
                        {ErrorPopUp ? ErrorPopUp : <>

                            <p>
                                {formik.touched.DoctorName && formik.errors.DoctorName ? (
                                    <div className='text-required'>{formik.errors.DoctorName}</div>
                                ) : null}
                            </p>
                            <p>{formik.touched.MiddleName && formik.errors.MiddleName ? (
                                <div className='text-required'>{formik.errors.MiddleName}</div>
                            ) : null}</p>
                            <p>{formik.touched.LastName && formik.errors.LastName ? (
                                <div className='text-required'>{formik.errors.LastName}</div>
                            ) : null}</p>

                            <p>
                                {formik.touched.sapStateId && formik.errors.sapStateId ? (
                                    <div className='text-required'>{formik.errors.sapStateId}</div>
                                ) : null}
                            </p>
                            <p>
                                {formik.touched.sapRegionId && formik.errors.sapRegionId ? (
                                    <div className='text-required'>{formik.errors.sapRegionId}</div>
                                ) : null}
                            </p>
                            <p>
                                {formik.touched.sapDistrictId && formik.errors.sapDistrictId ? (
                                    <div className='text-required'>{formik.errors.sapDistrictId}</div>
                                ) : null}
                            </p>
                            <p>
                                {formik.touched.Specialization && formik.errors.Specialization ? (
                                    <div className='text-required'>{formik.errors.Specialization}</div>
                                ) : null}
                            </p>
                            <p>
                                {globallocationStateList.length == 0 ? (<div className='text-required'>{"State Selection is Required"}</div>) : null}
                            </p>
                            <p>
                                {LocationRegionList.length > 0 && LGlbLocationRegionList.length == 0 ? (<div className='text-required'>{"Region Selection is Required"}</div>) : null}
                            </p>
                            <p>
                                {LocationDistrictList.length > 0 && LGLocationDistrictList.length == 0 ? (<div className='text-required'>{"District Selection is Required"}</div>) : null}
                            </p>
                        </>}

                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>

                </Modal>
            }
        </Fragment>
    )
}

export default EditDoctorDetail;