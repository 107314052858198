import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Printb2bInvoice from './pages/admin/pdfPrints/Printb2bInvoice';
import B2BInvoices from './pages/admin/reports/B2BInvoices';
import 'bootstrap/dist/css/bootstrap.min.css';

import { firebase_app, auth0 } from './data/config';
import { configureFakeBackend, authHeader, handleResponse } from "./services/fack.backend";
import { BrowserRouter, Switch, Route, Redirect, Link } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// ** Import custom css and alerts **
import './pages/admin/layout.css';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

// ** Import custom components for redux **
import { Provider } from 'react-redux';
import store from './store';
import App from "./components/app";
import { Auth0Provider } from '@auth0/auth0-react'

// Import custom Components 
import Dashboard from './pages/admin/Dashboard';
import RatePlan from './pages/admin/master/RatePlan';
import RatePlanMapping from './pages/admin/master/RatePlanMapping';
import LISDepartment from './pages/admin/LIS_master/LISDepartment';
import SampleContainer from './pages/admin/LIS_master/SampleContainer';
import SampleTypes from './pages/admin/LIS_master/SampleTypes';
import Documents from './pages/admin/LIS_master/Documents';
import LISPackages from './pages/admin/LIS_master/LISPackages';
import LIS_States from './pages/admin/LIS_master/LIS_States';
import LIS_Cities from './pages/admin/LIS_master/LIS_Cities';
import Localties from './pages/admin/LIS_master/Localties';
import LabObservation from './pages/admin/LIS_master/LabObservation';
import Investigations from './pages/admin/LIS_master/Investigations';
import RequiredFields from './pages/admin/LIS_master/RequiredFields';
import InvestigationRequiredAttachment from './pages/admin/LIS_master/InvestigationRequiredAttachment';
import DisplayRatePlan from './pages/admin/master/DisplayRatePlan';
import Centres from './pages/admin/master/Centres';
import CentreInfo from './pages/admin/master/CentreInfo';
import Locations from './pages/admin/Location_master/Locations';
import Cities from './pages/admin/Location_master/Cities';
import States from './pages/admin/Location_master/States';
import TestCategories from './pages/admin/master/TestCategories';
import Banners from './pages/admin/master/Banners';
import DashboardBanners from './pages/admin/master/DashboardBanners';
import CorporateGovernance from './pages/admin/master/CorporateGovernance';
import MeetingandNotices from './pages/admin/master/MeetingandNotices';
import SchemeofAmalgamation from './pages/admin/master/SchemeofAmalgamation';
import ItemWisePanelRateList from './pages/admin/master/ItemWisePanelRateList';
import HrmsSyncSystem from './pages/admin/HRMS_master/hrmssyncsystem';
import ZoneMaster from './pages/admin/HRMS_master/ZoneMaster';
import DepartmentMaster from './pages/admin/HRMS_master/departmentmaster';
import Designationmaster from './pages/admin/HRMS_master/designationmaster';
import Branchmaster from './pages/admin/HRMS_master/branchmaster';
import Employeegroupmaster from './pages/admin/HRMS_master/employeegroupmaster';
import Employeetypemaster from './pages/admin/HRMS_master/employeetypemaster';
import Jobtypemaster from './pages/admin/HRMS_master/jobtypemaster';
import Companymaster from './pages/admin/HRMS_master/companymaster';
import Employeemaster from './pages/admin/HRMS_master/employeemaster';
import ViewEmployeeDetails from './pages/admin/HRMS_master/ViewEmployeeDetails';
import Doctors from './pages/admin/Doctor_master/Doctors';
import AddDoctorDetail from './pages/admin/Doctor_master/AddDoctorDetail';
import ViewDoctorDetail from './pages/admin/Doctor_master/ViewDoctorDetail';
import EditDoctorDetail from './pages/admin/Doctor_master/EditDoctorDetail';
import Users from './pages/admin/User_Master/Users';
import AddAccessGroup from './pages/admin/User_Master/AddAccessGroup';
import AccessGroups from './pages/admin/User_Master/AccessGroups';
import EditAccessGroup from './pages/admin/User_Master/EditAccessGroup';
import AccessGroupsMapping from './pages/admin/User_Master/AccessGroupsMapping';
import AddAccessGroupsMapping from './pages/admin/User_Master/AddAccessGroupsMapping';
import AccessDenied from './pages/admin/AccessDenied';
import LDAPSync from './pages/admin/User_Master/LDAPSync';
import PendingApprovalDoctors from './pages/admin/Doctor_master/PendingApprovalDoctors';
import DoctorMapping from './pages/admin/Doctor_master/DoctorMapping';
import DoctorToBusinessMapping from './pages/admin/Doctor_master/DoctortoBusinessMapping';
import DoctorMergingDetails from './pages/admin/Doctor_master/DoctorMergingDetails';
import AddDoctorMergingDetails from './pages/admin/Doctor_master/AddDoctorMergingDetails';
import EditAccessGroupLocation from './pages/admin/User_Master/EditAccessGroupLocation';
import SampleRejections from './pages/admin/LIS_master/SampleRejections';
import FieldBoys from './pages/admin/master/FieldBoys';
import FieldBoyDetails from './pages/admin/master/FieldBoyDetails';
import PackageMarkers from './pages/admin/master/PackageMarkers';
import PackageMarkersDetails from './pages/admin/master/PackageMarkersDetails';
import PackageCategories from './pages/admin/master/PackageCategories';
import PackageCategoryDetails from './pages/admin/master/PackageCategoryDetails';
import Packages from './pages/admin/master/Packages';
import PackageDetails from './pages/admin/master/PackageDetails';
import Discounts from './pages/admin/master/Discounts';
import AddDiscount from './pages/admin/master/AddDiscount';
import EditDiscount from './pages/admin/master/EditDiscount';
import Credits from './pages/admin/master/Credits';
import AddCredit from './pages/admin/master/AddCredit';
import EditCredit from './pages/admin/master/EditCredit';
import ValidatePayment from './pages/admin/accounts/ValidatePayment';
import CentreDetailsPrint from './pages/admin/common/CentreDetailsPrint';
import CentreDetailsMakerPrint from './pages/admin/common/CentreDetailsMakerPrint';
import DiscountApproval from './pages/admin/accounts/DiscountApproval';
import CreditApproval from './pages/admin/accounts/CreditApproval';
import SettleDiscount from './pages/admin/accounts/Approve-amount';
import SAPStates from './pages/admin/sapLocations/SAPStates';
import SAPRegions from './pages/admin/sapLocations/SAPRegions';
import SAPDistricts from './pages/admin/sapLocations/SAPDistricts';
import CostcodeModalityMapping from './pages/admin/sapLocations/CostcodeModalityMapping';
import SalesReport from './pages/admin/sapLocations/SalesReport';
import SalesReportNew from './pages/admin/sapLocations/SalesReportNew';
import PRO from './pages/admin/master/PRO';
import PRODetails from './pages/admin/master/PRODetails';
import ValidateBankPayment from './pages/admin/accounts/ValidateBankPayment';
import BankCashFlow from './pages/admin/accounts/BankCashFlow';
import Ledgers from './pages/admin/accounts/Ledgers';
import LedgersNew from './pages/admin/accounts/LedgersNew';
import AdvancePayment from './pages/admin/accounts/AdvancePayment';
import ShortageLimits from './pages/admin/master/ShortageLimits';
import ShortageLimitInfo from './pages/admin/master/ShortageLimitInfo';
import POSMachines from './pages/admin/master/POSMachines';
import dayendreport from './pages/admin/accounts/DayEndReport';
import PartnerBilling from './pages/admin/accounts/PartnerBilling';
import CentreRateTemplate from './pages/admin/reports/CentreRateTemplate';
import RegistrationDiscounts from './pages/admin/reports/RegistrationDiscounts';
import ModewiseCollections from './pages/admin/reports/ModewiseCollections';
import ServiceWiseCollections from './pages/admin/reports/ServiceWiseCollections';
import CentreMasterData from './pages/admin/reports/CentreMasterData';
import CustomerCollections from './pages/admin/reports/CustomerCollections';
import BusinessData from './pages/admin/reports/BusinessData';
import PatientInformation from './pages/admin/reports/PatientInformation';
import DebitAndCreditNote from './pages/admin/reports/DebitAndCreditNote';
import RefundStatus from './pages/admin/reports/RefundStatus';
import RegistrationCredit from './pages/admin/reports/RegistrationCredit';
import MakerCentre from './pages/admin/master/MakerCentre';
import CheckerCentre from './pages/admin/master/CheckerCentre';
import CentreInfoMaker from './pages/admin/master/CentreInfoMaker';
import CentreInfoChecker from './pages/admin/master/CentreInfoChecker';
import UserAccessGroups from './pages/admin/User_Master/UserAccessGroups';
import BankPOSFlow from './pages/admin/accounts/BankPOSFlow';
import Doctortitle from './pages/admin/Doctor_master/Doctortitle';
import DoctorUploadBusinessMapping from './pages/admin/Doctor_master/DoctorUploadBusinessMapping';
import DoctorBusinessMapping from './pages/admin/Doctor_master/DoctorBusinessMapping';
import ValidateDrBusinessMapping from './pages/admin/Doctor_master/ValidateDrBusinessMapping';
import DoctorBusinessMappingMaster from './pages/admin/Doctor_master/DoctorBusinessMappingMaster';
import RecoveryFromApprover from './pages/admin/accounts/RecoveryFromApprover';
import RouteMaster from './pages/admin/master/RouteMaster';
import RoutePhleboMapping from './pages/admin/master/RoutePhleboMapping';
import BillsPending from './pages/admin/reports/BillsPending';
import UnpaidBills from './pages/admin/reports/UnpaidBills';
import UnapprovedDiscount from './pages/admin/reports/UnapprovedDiscount';
import UnapprovedCredit from './pages/admin/reports/UnapprovedCredit';
import CreateInvoice from './pages/admin/BulkInvoice/CreateInvoice';
import ViewInvoice from './pages/admin/BulkInvoice/ViewInvoice';
import ReportInvoice from './pages/admin/BulkInvoice/ReportInvoice';
import PrintCyclotronInvoice from './pages/admin/pdfPrints/PrintCyclotronInvoice';
import OnlineReport from './pages/admin/reports/OnlineReport';
import ValidatePaymentReport from './pages/admin/reports/ValidatePaymentReport';
import DCRReports from './pages/admin/reports/DCRReports';
import SaleTrendvsVisitTrend from './pages/admin/reports/SaleTrendvsVisitTrend';
import B2BReconcilation from './pages/admin/reports/B2BReconcilation';
import UserReconcilation from './pages/admin/reports/UserReconcilation';
import GeneratePartB from './pages/admin/BulkInvoice/GeneratePartB';
import SaleRegister from './pages/admin/BulkInvoice/SaleRegister';
import ManualUsers from './pages/admin/User_Master/ManualUsers';
import PrintReceipt from './pages/admin/PrintReceipt';
import CentrePanelTemplateMapping from './pages/admin/master/CentrePanelTemplateMapping';
import AddEditCentrePanelTemplateMapping from './pages/admin/master/AddEditCentrePanelTemplateMapping';
import UserCentreTemplatesMapping from './pages/admin/master/UserCentreTemplatesMapping';
import AddEditUserCentreTemplatesMapping from './pages/admin/master/AddEditUserCentreTemplatesMapping';
// pages 
import Login from './pages/login';
import Signup from './pages/signup';
import UnlockUser from './pages/unlockUser';
import ForgetPwd from './pages/forgetPwd';
import ResetPwd from './pages/resetPwd';
import ComingSoon from './pages/comingsoon';
import Maintenance from './pages/maintenance';
import Error400 from './pages/errors/error400';
import Error401 from './pages/errors/error401';
import Error403 from './pages/errors/error403';
import Error404 from './pages/errors/error404';
import Error500 from './pages/errors/error500';
import Error503 from './pages/errors/error503';

// Import Applications Components
import Signin from './auth/signin';

//config data
import configDB from './data/customizer/config'
import Callback from './auth/callback'
import SweetAlert from 'sweetalert2';

// setup fake backend
configureFakeBackend();

window.GlobalBusiness = "0";
const Root = () => {
    const UserAccessKey=localStorage.UserAccessKey == undefined ? "" : localStorage.UserAccessKey;
    const abortController = new AbortController();
    const [currentUser, setCurrentUser] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const Session = localStorage.getItem('Session');
    let params = (new URL( window.location)).searchParams;
    let AccessKeyCust = params.get("Loginkey");
    // const jwt_token = localStorage.getItem('token');

    useEffect(() => {
        document.onclick = function (event) {
            // if (localStorage.getItem("UserAccessKey") == null || localStorage.getItem("UserAccessKey") == "" || localStorage.getItem("UserAccessKey") == undefined) { }
            // else {
            //     localStorage.clear();
            //     window.location.assign(`${process.env.PUBLIC_URL}/login`);
            // }
            const ddd = window.location.href;
            var n = ddd.lastIndexOf('/');
            var result = ddd.substring(n + 1);
            const today = new Date();
            const date = today.setDate(today.getDate());
            const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
            if (result != 'login' && (localStorage.getItem("LoginDate") == null || localStorage.getItem("LoginDate") == "" || localStorage.getItem("LoginDate") == undefined || localStorage.getItem("LoginDate") != defaultValue)) {
                SweetAlert.fire({
                    title: 'Session Expired!',
                    text: "Please login again",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        localStorage.clear();
                        window.location.assign(`${process.env.PUBLIC_URL}/login`);
                    }
                    else {
                        localStorage.clear();
                        window.location.assign(`${process.env.PUBLIC_URL}/login`);
                    }
                })
            }
        };
        window.addEventListener("load", function () {
            const cccc = window.location.href;
            var ncc = cccc.lastIndexOf('/');
            var result = cccc.substring(ncc + 1);
            const today = new Date();
            const date = today.setDate(today.getDate());
            const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
            if (result != 'login' && (localStorage.getItem("LoginDate") == null || localStorage.getItem("LoginDate") == "" || localStorage.getItem("LoginDate") == undefined || localStorage.getItem("LoginDate") != defaultValue)) {
                SweetAlert.fire({
                    title: 'Session Expired!',
                    text: "Please login again",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        localStorage.clear();
                        window.location.assign(`${process.env.PUBLIC_URL}/login`);
                    }
                    else {
                        localStorage.clear();
                        window.location.assign(`${process.env.PUBLIC_URL}/login`);
                    }
                })
            }
        });
        window.addEventListener('storage', () => {
            if(UserAccessKey!="" && UserAccessKey!=window.localStorage.getItem("UserAccessKey"))
            {
             console.log('reload');
             window.location.assign(`/dashboard`);
            }
             console.log('ppp'+UserAccessKey);
            console.log('TEST---ssAfeter'+window.localStorage.getItem("UserAccessKey"));
          });
        const requestOptions = { method: 'GET', headers: authHeader() };
        fetch('/users', requestOptions).then(handleResponse)
        const color = localStorage.getItem('color')
        console.log(color);
        const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version
        firebase_app.auth().onAuthStateChanged(setCurrentUser);
        setAuthenticated(JSON.parse(localStorage.getItem("authenticated")))
        document.body.classList.add(layout);
        console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
        console.disableYellowBox = true;
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);

        return function cleanup() {
            abortController.abort();
        }
   
     


        // eslint-disable-next-line
    }, []);

    return (
        <div className="App">
            <Provider store={store}>
                <BrowserRouter basename={`/`}>
                    <Switch>
                        <Route path={`${process.env.PUBLIC_URL}/login`} component={Signin} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/login`} component={Login} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/signup`} component={Signup} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/unlockUser`} component={UnlockUser} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/forgetPwd`} component={ForgetPwd} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/resetPwd`} component={ResetPwd} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/comingsoon`} component={ComingSoon} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error400`} component={Error400} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error401`} component={Error401} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error403`} component={Error403} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error404`} component={Error404} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error500`} component={Error500} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/errors/error503`} component={Error503} />
                        <Route path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback />} />
                        <Route path={`${process.env.PUBLIC_URL}/accessDenied`} caseSensitive={false} component={AccessDenied} />
                        <Route path={`${process.env.PUBLIC_URL}/common/centredetailsprint`} caseSensitive={false} component={CentreDetailsPrint} />
                        <Route path={`${process.env.PUBLIC_URL}/common/centredetailsmakerprint`} caseSensitive={false} component={CentreDetailsMakerPrint} />
                        <Route exact path={`${process.env.PUBLIC_URL}/pdfprints/printb2binvoice`} caseSensitive={false} component={Printb2bInvoice} />
                        <Route path={`${process.env.PUBLIC_URL}/pdfPrints/PrintCyclotronInvoice`} caseSensitive={false} component={PrintCyclotronInvoice} />
                        <Route path={`${process.env.PUBLIC_URL}/admin/receipt`} caseSensitive={false} component={PrintReceipt} />
                        {/* {currentUser !== null || authenticated || jwt_token ? */}

                        {Session == "Active" ?
                            <App>
                                {/* dashboard menu */}
                                {/* <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                    return (<Redirect to={`${process.env.PUBLIC_URL}/master/centres`} />)
                                }} /> */}

                                <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                    return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />)
                                }} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/department`} component={Dashboard} /> */}
                                <Route path={`${process.env.PUBLIC_URL}/master/centres`} caseSensitive={false} component={Centres} />
                                <Route path={`${process.env.PUBLIC_URL}/master/maker-centre`} caseSensitive={false} component={MakerCentre} />
                                <Route path={`${process.env.PUBLIC_URL}/master/checker-centre`} caseSensitive={false} component={CheckerCentre} />
                                <Route path={`${process.env.PUBLIC_URL}/master/centre-info`} caseSensitive={false} component={CentreInfo} />
                                <Route path={`${process.env.PUBLIC_URL}/master/centre-info-maker`} caseSensitive={false} component={CentreInfoMaker} />
                                <Route path={`${process.env.PUBLIC_URL}/master/centre-info-checker`} caseSensitive={false} component={CentreInfoChecker} />
                                <Route path={`${process.env.PUBLIC_URL}/master/rate-plan`} caseSensitive={false} component={RatePlan} />
                                <Route path={`${process.env.PUBLIC_URL}/master/rate-plan-mapping`} caseSensitive={false} component={RatePlanMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/master/centrepaneltemplatemapping`} caseSensitive={false} component={CentrePanelTemplateMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/master/addeditcentrepaneltemplatemapping`} caseSensitive={false} component={AddEditCentrePanelTemplateMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/master/usercentretemplatesmapping`} caseSensitive={false} component={UserCentreTemplatesMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/master/addeditusercentretemplatesmapping`} caseSensitive={false} component={AddEditUserCentreTemplatesMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/master/test-categories`} caseSensitive={false} component={TestCategories} />
                                <Route path={`${process.env.PUBLIC_URL}/lis-master/department`} caseSensitive={false} component={LISDepartment} />
                                <Route path={`${process.env.PUBLIC_URL}/lis-master/sample-container`} caseSensitive={false} component={SampleContainer} />
                                <Route path={`${process.env.PUBLIC_URL}/lis-master/sample-types`} caseSensitive={false} component={SampleTypes} />
                                <Route path={`${process.env.PUBLIC_URL}/lis-master/packages`} caseSensitive={false} component={LISPackages} />
                                <Route path={`${process.env.PUBLIC_URL}/lis-master/documents`} caseSensitive={false} component={Documents} />
                                <Route path={`${process.env.PUBLIC_URL}/lis-master/states`} caseSensitive={false} component={LIS_States} />
                                <Route path={`${process.env.PUBLIC_URL}/lis-master/cities`} caseSensitive={false} component={LIS_Cities} />
                                <Route path={`${process.env.PUBLIC_URL}/lis-master/localities`} caseSensitive={false} component={Localties} />
                                <Route path={`${process.env.PUBLIC_URL}/lis-master/labobservations`} caseSensitive={false} component={LabObservation} />
                                <Route path={`${process.env.PUBLIC_URL}/lis-master/investigations`} caseSensitive={false} component={Investigations} />
                                <Route path={`${process.env.PUBLIC_URL}/lis-master/requiredfields`} caseSensitive={false} component={RequiredFields} />
                                <Route path={`${process.env.PUBLIC_URL}/lis-master/investigationrequiredattachment`} caseSensitive={false} component={InvestigationRequiredAttachment} />
                                <Route path={`${process.env.PUBLIC_URL}/lis-master/samplerejections`} caseSensitive={false} component={SampleRejections} />
                                <Route path={`${process.env.PUBLIC_URL}/master/rateplan`} caseSensitive={false} component={RatePlan} />
                                <Route path={`${process.env.PUBLIC_URL}/master/displayrateplan`} caseSensitive={false} component={DisplayRatePlan} />
                                <Route path={`${process.env.PUBLIC_URL}/master/rateplanmapping`} caseSensitive={false} component={RatePlanMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/master/fieldboys`} caseSensitive={false} component={FieldBoys} />
                                <Route path={`${process.env.PUBLIC_URL}/master/fieldboy-info`} caseSensitive={false} component={FieldBoyDetails} />
                                <Route path={`${process.env.PUBLIC_URL}/master/packagemarkers`} caseSensitive={false} component={PackageMarkers} />
                                <Route path={`${process.env.PUBLIC_URL}/master/packagemarkers-info`} caseSensitive={false} component={PackageMarkersDetails} />
                                <Route path={`${process.env.PUBLIC_URL}/master/packagecategories`} caseSensitive={false} component={PackageCategories} />
                                <Route path={`${process.env.PUBLIC_URL}/master/packagecategory-info`} caseSensitive={false} component={PackageCategoryDetails} />
                                <Route path={`${process.env.PUBLIC_URL}/master/packages`} caseSensitive={false} component={Packages} />
                                <Route path={`${process.env.PUBLIC_URL}/master/package-info`} caseSensitive={false} component={PackageDetails} />
                                <Route path={`${process.env.PUBLIC_URL}/hrms-master/hrmssyncsystem`} caseSensitive={false} component={HrmsSyncSystem} />
                                <Route path={`${process.env.PUBLIC_URL}/hrms-master/ZoneMaster`} caseSensitive={false} component={ZoneMaster} />
                                <Route path={`${process.env.PUBLIC_URL}/hrms-master/departmentmaster`} caseSensitive={false} component={DepartmentMaster} />
                                <Route path={`${process.env.PUBLIC_URL}/hrms-master/designationmaster`} caseSensitive={false} component={Designationmaster} />
                                <Route path={`${process.env.PUBLIC_URL}/hrms-master/branchmaster`} caseSensitive={false} component={Branchmaster} />
                                <Route path={`${process.env.PUBLIC_URL}/hrms-master/employeegroupmaster`} caseSensitive={false} component={Employeegroupmaster} />
                                <Route path={`${process.env.PUBLIC_URL}/hrms-master/employeetypemaster`} caseSensitive={false} component={Employeetypemaster} />
                                <Route path={`${process.env.PUBLIC_URL}/hrms-master/jobtypemaster`} caseSensitive={false} component={Jobtypemaster} />
                                <Route path={`${process.env.PUBLIC_URL}/hrms-master/companymaster`} caseSensitive={false} component={Companymaster} />
                                <Route path={`${process.env.PUBLIC_URL}/hrms-master/employeemaster`} caseSensitive={false} component={Employeemaster} />
                                <Route path={`${process.env.PUBLIC_URL}/location-master/states`} caseSensitive={false} component={States} />
                                <Route path={`${process.env.PUBLIC_URL}/location-master/cities`} caseSensitive={false} component={Cities} />
                                <Route path={`${process.env.PUBLIC_URL}/location-master/locations`} caseSensitive={false} component={Locations} />
                                <Route path={`${process.env.PUBLIC_URL}/hrms-master/ViewEmployeeDetails`} caseSensitive={false} component={ViewEmployeeDetails} />
                                <Route path={`${process.env.PUBLIC_URL}/doctor-master/doctors`} caseSensitive={false} component={Doctors} />
                                <Route path={`${process.env.PUBLIC_URL}/doctor-master/adddoctorDetail`} caseSensitive={false} component={AddDoctorDetail} />
                                <Route path={`${process.env.PUBLIC_URL}/doctor-master/viewdoctorDetail`} caseSensitive={false} component={ViewDoctorDetail} />
                                <Route path={`${process.env.PUBLIC_URL}/doctor-master/editdoctorDetail`} caseSensitive={false} component={EditDoctorDetail} />
                                <Route path={`${process.env.PUBLIC_URL}/user-master/users`} caseSensitive={false} component={Users} />
                                <Route path={`${process.env.PUBLIC_URL}/user-master/accessgroups`} caseSensitive={false} component={AccessGroups} />
                                <Route path={`${process.env.PUBLIC_URL}/user-master/addAccessGroup`} caseSensitive={false} component={AddAccessGroup} />
                                <Route path={`${process.env.PUBLIC_URL}/user-master/editAccessGroup`} caseSensitive={false} component={EditAccessGroup} />
                                <Route path={`${process.env.PUBLIC_URL}/user-master/accessGroupsMapping`} caseSensitive={false} component={AccessGroupsMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/user-master/addAccessGroupsMapping`} caseSensitive={false} component={AddAccessGroupsMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/dashboard`} caseSensitive={false} component={Dashboard} />
                                <Route path={`${process.env.PUBLIC_URL}/user-master/lDAPSync`} caseSensitive={false} component={LDAPSync} />
                                <Route path={`${process.env.PUBLIC_URL}/doctor-master/pendingApprovalDoctors`} caseSensitive={false} component={PendingApprovalDoctors} />
                                <Route path={`${process.env.PUBLIC_URL}/doctor-master/doctorMapping`} caseSensitive={false} component={DoctorMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/doctor-master/doctortoBusinessMapping`} caseSensitive={false} component={DoctorToBusinessMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/doctor-master/doctorMergingDetails`} caseSensitive={false} component={DoctorMergingDetails} />
                                <Route path={`${process.env.PUBLIC_URL}/doctor-master/adddoctorMergingDetails`} caseSensitive={false} component={AddDoctorMergingDetails} />
                                <Route path={`${process.env.PUBLIC_URL}/user-master/editAccessGroupLocation`} caseSensitive={false} component={EditAccessGroupLocation} />
                                <Route path={`${process.env.PUBLIC_URL}/master/discounts`} caseSensitive={false} component={Discounts} />
                                <Route path={`${process.env.PUBLIC_URL}/master/addDiscount`} caseSensitive={false} component={AddDiscount} />
                                <Route path={`${process.env.PUBLIC_URL}/master/editDiscount`} caseSensitive={false} component={EditDiscount} />
                                <Route path={`${process.env.PUBLIC_URL}/master/credits`} caseSensitive={false} component={Credits} />
                                <Route path={`${process.env.PUBLIC_URL}/master/addCredit`} caseSensitive={false} component={AddCredit} />
                                <Route path={`${process.env.PUBLIC_URL}/master/editCredit`} caseSensitive={false} component={EditCredit} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/validatePayment`} caseSensitive={false} component={ValidatePayment} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/discounts`} caseSensitive={false} component={DiscountApproval} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/credits`} caseSensitive={false} component={CreditApproval} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/approve-amount`} caseSensitive={false} component={SettleDiscount} />
                                <Route path={`${process.env.PUBLIC_URL}/sap/states`} caseSensitive={false} component={SAPStates} />
                                <Route path={`${process.env.PUBLIC_URL}/sap/regions`} caseSensitive={false} component={SAPRegions} />
                                <Route path={`${process.env.PUBLIC_URL}/sap/districts`} caseSensitive={false} component={SAPDistricts} />
                                <Route path={`${process.env.PUBLIC_URL}/sap/costcode-modality-mappinglist`} caseSensitive={false} component={CostcodeModalityMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/settle-amount`} caseSensitive={false} component={SettleDiscount} />
                                <Route path={`${process.env.PUBLIC_URL}/master/pro-list`} caseSensitive={false} component={PRO} />
                                <Route path={`${process.env.PUBLIC_URL}/master/pro-info`} caseSensitive={false} component={PRODetails} />  
                                <Route path={`${process.env.PUBLIC_URL}/master/banners`} caseSensitive={false} component={Banners} />
                                <Route path={`${process.env.PUBLIC_URL}/master/dashboardbanners`} caseSensitive={false} component={DashboardBanners} />
                                <Route path={`${process.env.PUBLIC_URL}/sap/salesreport`} caseSensitive={false} component={SalesReport} />
                                <Route path={`${process.env.PUBLIC_URL}/sap/salesreportNew`} caseSensitive={false} component={SalesReportNew} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/validatebankpayment`} caseSensitive={false} component={ValidateBankPayment} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/bankcashflow`} caseSensitive={false} component={BankCashFlow} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/ledgers`} caseSensitive={false} component={Ledgers} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/ledgersnew`} caseSensitive={false} component={LedgersNew} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/advancepayment`} caseSensitive={false} component={AdvancePayment} />
                                <Route path={`${process.env.PUBLIC_URL}/master/corporategovernance`} caseSensitive={false} component={CorporateGovernance} />
                                <Route path={`${process.env.PUBLIC_URL}/master/meetingandnotices`} caseSensitive={false} component={MeetingandNotices} />
                                <Route path={`${process.env.PUBLIC_URL}/master/shortagelimits`} caseSensitive={false} component={ShortageLimits} />
                                <Route path={`${process.env.PUBLIC_URL}/master/shortagelimit-info`} caseSensitive={false} component={ShortageLimitInfo} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/dayendreport`} caseSensitive={false} component={dayendreport} /> 
                                <Route path={`${process.env.PUBLIC_URL}/accounts/PartnerBilling`} caseSensitive={false} component={PartnerBilling} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/centreratetemplate`} caseSensitive={false} component={CentreRateTemplate} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/registrationdiscounts`} caseSensitive={false} component={RegistrationDiscounts} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/modewisecollections`} caseSensitive={false} component={ModewiseCollections} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/servicewisecollections`} caseSensitive={false} component={ServiceWiseCollections} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/centresmasterdata`} caseSensitive={false} component={CentreMasterData} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/usercollections`} caseSensitive={false} component={CustomerCollections} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/businessdata`} caseSensitive={false} component={BusinessData} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/patientinformation`} caseSensitive={false} component={PatientInformation} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/DebitAndCreditNote`} caseSensitive={false} component={DebitAndCreditNote} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/RefundStatus`} caseSensitive={false} component={RefundStatus} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/registrationcredit`} caseSensitive={false} component={RegistrationCredit} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/OnlineReport`} caseSensitive={false} component={OnlineReport} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/ValidatePaymentReport`} caseSensitive={false} component={ValidatePaymentReport} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/B2BReconcilation`} caseSensitive={false} component={B2BReconcilation} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/UserReconcilation`} caseSensitive={false} component={UserReconcilation} />
                                <Route path={`${process.env.PUBLIC_URL}/user-master/userAccessgroups`} caseSensitive={false} component={UserAccessGroups} /> 
                                <Route path={`${process.env.PUBLIC_URL}/master/pos-machines`} caseSensitive={false} component={POSMachines} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/bankposflow`} caseSensitive={false} component={BankPOSFlow} />
                                <Route path={`${process.env.PUBLIC_URL}/reports/b2binvoices`} caseSensitive={false} component={B2BInvoices} /> 
                                <Route path={`${process.env.PUBLIC_URL}/doctor-master/Doctortitle`} caseSensitive={false} component={Doctortitle} />
                                <Route path={`${process.env.PUBLIC_URL}/doctor-master/DoctorUploadBusinessMapping`} caseSensitive={false} component={DoctorUploadBusinessMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/doctor-master/DoctorBusinessMapping`} caseSensitive={false} component={DoctorBusinessMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/doctor-master/ValidateDrBusinessMapping`} caseSensitive={false} component={ValidateDrBusinessMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/doctor-master/DoctorBusinessMappingMaster`} caseSensitive={false} component={DoctorBusinessMappingMaster} />
                                <Route path={`${process.env.PUBLIC_URL}/accounts/recoveryfromapprover`} caseSensitive={false} component={RecoveryFromApprover} />
                                <Route path={`${process.env.PUBLIC_URL}/master/RouteMaster`} caseSensitive={false} component={RouteMaster} />
                                <Route path={`${process.env.PUBLIC_URL}/master/RoutePhleboMapping`} caseSensitive={false} component={RoutePhleboMapping} />
                                <Route path={`${process.env.PUBLIC_URL}/master/schemeofamalgamation`} caseSensitive={false} component={SchemeofAmalgamation} />
                                <Route path={`${process.env.PUBLIC_URL}/reports/billspendingordersgenerated`} caseSensitive={false} component={BillsPending} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/unpaidbills`} caseSensitive={false} component={UnpaidBills} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/unapproveddiscount`} caseSensitive={false} component={UnapprovedDiscount} /> 
                                <Route path={`${process.env.PUBLIC_URL}/reports/unapprovedcredit`} caseSensitive={false} component={UnapprovedCredit} /> 
                                <Route path={`${process.env.PUBLIC_URL}/BulkInvoice/Create-Invoice`} caseSensitive={false} component={CreateInvoice} />
                                <Route path={`${process.env.PUBLIC_URL}/BulkInvoice/View-Invoice`} caseSensitive={false} component={ViewInvoice} />
                                <Route path={`${process.env.PUBLIC_URL}/BulkInvoice/Report-Invoice`} caseSensitive={false} component={ReportInvoice} /> 
                                <Route path={`${process.env.PUBLIC_URL}/BulkInvoice/SaleRegister`} caseSensitive={false} component={SaleRegister} /> 
                                <Route exact path={`${process.env.PUBLIC_URL}/reports/dcrreports`} caseSensitive={false} component={DCRReports} /> 
                                <Route exact path={`${process.env.PUBLIC_URL}/reports/saletrendVsvisittrend`} caseSensitive={false} component={SaleTrendvsVisitTrend} /> 
                                <Route path={`${process.env.PUBLIC_URL}/master/itemwiserateplanlist`} caseSensitive={false} component={ItemWisePanelRateList} />
                                <Route path={`${process.env.PUBLIC_URL}/BulkInvoice/Generate-Part-B`} caseSensitive={false} component={GeneratePartB} />
                                <Route path={`${process.env.PUBLIC_URL}/user-master/manualusers`} caseSensitive={false} component={ManualUsers} />
                                
                            </App>
                            :
                            <Redirect to={`${process.env.PUBLIC_URL}/login`} />
                        }
                    </Switch>
                </BrowserRouter>
            </Provider>
        </div>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();