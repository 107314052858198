import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import { URL_Start } from '../../../constant';

const Printb2bInvoice = () => {

  const [CentreName, setCentreName] = useState("");
  const [InvoiceNo, setInvoiceNo] = useState("");
  const [patientservicedetail, setPatientservicedetail] = useState([]);
  const [TotalAmount, setTotalAmount] = useState("0.00");
  const [AmountInWords, setAmountInWords] = useState("0.00");
  const [EamilId, setEamilId] = useState("");
  const [CentreAddress, setCentreAddress] = useState("");
  const [CompanyCINNo, setCompanyCINNo] = useState("");
  const [RegisteredOffice, setRegisteredOffice] = useState("");
  const [CoprateOffice, setCoprateOffice] = useState("");
  const [CentreLocations, setCentreLocations] = useState("");
  const [OurServices, setOurServices] = useState("");
  const [CentreGSTNo, setCentreGSTNo] = useState("");
  const [durationPeriod, SetdurationPeriod] = useState("");
  const [currentPeriod, SetcurrentPeriod] = useState("");
  const [CentrePhone, SetCentrePhone] = useState("");
  const [TotalTestCount, SetTotalTestCount] = useState("0");
  const [PatientTestwiseetail, setPatientTestwiseetail] = useState([]);

  const [AccountName, setAccountName] = useState("");
  const [AccountBank, setAccountBank] = useState("");
  const [AccountNo, setAccountNo] = useState("");
  const [IFSCCode, setIFSCCode] = useState("");
  const [GSTNo, setGSTNo] = useState("");

  useEffect(() => {
    GetReportData();
  }, [])

  const GetReportData = async () => {
    const ObjServiceCollection = {
      InvoiceId: localStorage.IID,
      LoginId: localStorage.LoggedInUser
    };
    try {
      let url = URL_Start + 'reports/GetB2bInvoiceGenerateReports'
      await axios.post(url, ObjServiceCollection).then((response) => {
        const data = response.data;
        if (data.Success) {
          let ds = data.Data;
          if (ds != null) {
            if (ds != null && ds.objB2BInvoiceCentreData != null) {

              setCentreAddress(ds.objB2BInvoiceCentreData.CentreAddress);
              setCompanyCINNo(ds.objB2BInvoiceCentreData.CompanyCINNo);
              setRegisteredOffice(ds.objB2BInvoiceCentreData.RegisteredOffice);
              setCoprateOffice(ds.objB2BInvoiceCentreData.CoprateOffice);
              setCentreLocations(ds.objB2BInvoiceCentreData.CentreLocations);
              setOurServices(ds.objB2BInvoiceCentreData.OurServices);
              setCentreGSTNo(ds.objB2BInvoiceCentreData.CentreGSTNo);
              setInvoiceNo(ds.objB2BInvoiceCentreData.InvoiceNo);
              setCentreName(ds.objB2BInvoiceCentreData.CentreName);
              SetCentrePhone(ds.objB2BInvoiceCentreData.CentrePhone);
              setEamilId(ds.objB2BInvoiceCentreData.CentreEmail);

            }
            else {
              setCentreAddress("");
              setCompanyCINNo("");
              setRegisteredOffice("");
              setCoprateOffice("");
              setCentreLocations("");
              setOurServices("");
              setCentreGSTNo("");
              setInvoiceNo("");
              setCentreName("");
              SetCentrePhone("");
              setEamilId("");
            }
            if (ds != null && ds.objB2BinvoicePeriodData != null) {
              const periods = ds.objB2BinvoicePeriodData.PeriodFromDate + " to " + ds.objB2BinvoicePeriodData.PeriodTODate;
              const currentperiod = ds.objB2BinvoicePeriodData.CurrentDate;
              SetdurationPeriod(periods);
              SetcurrentPeriod(currentperiod);
            }
            else {
              SetdurationPeriod("");
              SetcurrentPeriod("");
            }
            if (ds != null && ds.objB2BInvoiceTotalTestAmtData != null) {

              const Amtinwords = ds.objB2BInvoiceTotalTestAmtData.AmountInWords;
              setAmountInWords(Amtinwords);
              setTotalAmount(ds.objB2BInvoiceTotalTestAmtData.TotalAmount);
              SetTotalTestCount(ds.objB2BInvoiceTotalTestAmtData.TestCount);
              setAccountName(ds.objB2BInvoiceTotalTestAmtData.AccountName);
              setAccountBank(ds.objB2BInvoiceTotalTestAmtData.AccountBank);
              setAccountNo(ds.objB2BInvoiceTotalTestAmtData.AccountNo);
              setIFSCCode(ds.objB2BInvoiceTotalTestAmtData.IFSCCode);
              setGSTNo(ds.objB2BInvoiceTotalTestAmtData.GSTNo);
            }
            else {
              setAmountInWords("");
              setTotalAmount("0.00");
              SetTotalTestCount("0");
              setAccountName("");
              setAccountBank("");
              setAccountNo("");
              setIFSCCode("");
              setGSTNo("");
            }
            if (ds != null && ds.objB2BInvoiceTestCountDataList != null && ds.objB2BInvoiceTestCountDataList.length > 0) {
              setPatientservicedetail(ds.objB2BInvoiceTestCountDataList);
            }
            else {
              setPatientservicedetail([]);
            }
            if (ds != null && ds.objB2BInvoiceOrderDataList != null && ds.objB2BInvoiceOrderDataList.length > 0) {
              setPatientTestwiseetail(ds.objB2BInvoiceOrderDataList);
            }
            else {
              setPatientTestwiseetail([]);
            }
          }


        }
      }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
  }

  const ErrorFunction = async (ErrorCode) => {
    if (ErrorCode == "400")
      SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
    else if (ErrorCode == "401")
      SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
    else if (ErrorCode == "403")
      SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
    else if (ErrorCode == "404")
      SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
    else if (ErrorCode == "500")
      SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
    else if (ErrorCode == "503")
      SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
  }
  
  return (
    <>
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Body style={{ paddingTop: '0px' }}>
                <div className="formWrapper">
                  <div className="header">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="logo"> <img src="/static/media/logo-atulaya.fbfb36f9.png" alt="thumb" />
                          <p>Imaging & Laboratories</p>
                        </div>
                        <div className="headinginnersec">
                          <h1>Invoice </h1>
                        </div>
                      </div>
                      <div className="col-sm-6 mt-4">
                        <p>Local Address: <span className="dotedclass"><b>{CentreAddress}</b></span></p>
                        <p>CIN No.: <span className="dotedclass"><b>{CompanyCINNo}</b></span></p>
                        <p>GST No.: <span className="dotedclass"><b>{CentreGSTNo}</b></span></p>
                      </div>
                    </div>
                  </div>
                  <div className="main">
                    <div className="row mb-2">
                      <div className="col-sm-6"> Invoice No: <span className="dotedclass"><b>{InvoiceNo}</b></span></div>
                      <div className="col-sm-6"> Customer Name:  <span className="dotedclass"><b>{CentreName}</b></span></div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-sm-6"> Period: <span className="dotedclass"><b>{durationPeriod}</b></span></div>
                      <div className="col-sm-6"> Center Ph. No:  <span className="dotedclass"><b>{CentrePhone}</b></span></div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-sm-12"> Date: <span className="dotedclass"><b>{currentPeriod}</b></span></div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-sm-12">Registered Office: <span className="dotedclass"><b>{RegisteredOffice}</b></span></div>
                      <div className="col-sm-12">Corporate Office: <span className="dotedclass"><b>{CoprateOffice}</b></span></div>
                    </div>

                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col"> #</th>
                          <th scope="col"> Department </th>
                          <th scope="col"> Service Code </th>
                          <th scope="col"> Service Name </th>
                          <th scope="col"> SAC/HSN Code</th>
                          <th scope="col"> Test Count </th>
                          <th scope="col"> Rate </th>
                          <th scope="col"> Total Amount </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          patientservicedetail != [] ?
                            <>
                              {
                                patientservicedetail.map((d, index) =>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{d.Department}</td>
                                    <td>{d.ServiceCode}</td>
                                    <td>{d.ServiceName}</td>
                                    <td>{d.HSNCode}</td>
                                    <td>{d.TestCount}</td>
                                    <td>{d.Rate}</td>
                                    <td>{d.TotalAmount}</td>
                                  </tr>
                                )
                              }
                            </>
                            : null
                        }
                      </tbody>
                    </table>


                    <div className="row mb-2"></div>
                    <div className="row">
                      <div className="col-sm-12 text-right">Total Test : <b>{TotalTestCount}</b></div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 text-right"> Grand Total : <b>{TotalAmount}</b></div>
                    </div>
                    <div className="row mb-2"></div>
                    <div className="row">
                      <div className="col-sm-6"><b>Grand Total:</b>{AmountInWords}</div>
                      <div className="col-sm-6 text-right">
                        <b>Atulaya  Healthcare Private limited</b>
                        <br></br>Authorized Signature :
                      </div>
                    </div>
                    <div>
                      {{ EamilId } != "" ? <p>In case of any discrepancy, please mail us at  {EamilId}</p> : <p>In case of any discrepancy, please mail us at healthcare@atulaya.com</p>}
                    </div>
                    <div>
                      <p>Please remit the payment within 15 days of receipt of bill else interest will be charger @18% for delayed payment</p>
                    </div>
                    <div className="row mb-2 mt-4">
                      <div className='col-sm-12'>
                        <p className='mb-2'><b>Bank Details:</b></p>
                        <p className='mb-2'><b>A/c Name: </b>{AccountName}</p>
                        <p className='mb-2'><b>A/c Bank: </b>{AccountBank}</p>
                        <p className='mb-2'><b>A/c No: </b>{AccountNo}</p>
                        <p className='mb-2'><b>IFS Code: </b>{IFSCCode}</p>
                        <p className='mb-2'><b>GST NO: </b>{GSTNo}</p>
                      </div>
                    </div>
                    <div className="row mb-2"></div>
                    <div className='txtpagebreak'>&nbsp;</div>
                    <div className="row mb-2">
                      <div className="col-sm-12 text-center"><span className="dotedclass"><b>Patient Wise Test</b></span></div>

                    </div>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col"> #</th>
                          <th scope="col"> Posted Date </th>
                          <th scope="col"> Visit No. </th>
                          <th scope="col"> Patient Name</th>
                          <th scope="col"> Service Code </th>
                          <th scope="col"> Service Name </th>
                          <th scope="col"> SAC/HSN Code</th>
                          <th scope="col"> Rate </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          PatientTestwiseetail != [] ?
                            <>
                              {
                                PatientTestwiseetail.map((d, index) =>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{d.OrderDate}</td>
                                    <td>{d.VisitingCode}</td>
                                    <td>{d.PatientName}</td>
                                    <td>{d.ServiceCode}</td>
                                    <td>{d.ServiceName}</td>
                                    <td>{d.HSNCode}</td>
                                    <td>{d.Rate}</td>
                                  </tr>
                                )
                              }
                            </>
                            : null
                        }
                      </tbody>
                    </table>
                    <div className="row mb-2"></div>
                    <div className="row">
                      <div className="col-sm-12 text-right">Total Test : <b>{TotalTestCount}</b></div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 text-right"> Grand Total : <b>{TotalAmount}</b></div>
                    </div>
                    <div className="row mb-2"></div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default Printb2bInvoice;